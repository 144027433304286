export const productApis = {
  getAllProducts: (isRecommended: boolean = false) =>
    `/products?recommended=${isRecommended}`,
  getProducts: (siteId: number, isRecommended: boolean = false) =>
    `/products/${siteId}?recommended=${isRecommended}`,
  getBanners: `/banners`,
  getEvents: `/events`,
  getBrands: `/brands`,
  getBranches: `/branches`,
  getTrendings: `/trendings`,
};
