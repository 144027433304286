import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FacebookPixelEventTrackerService } from '../../core/services/facebook-pixel-event-tracker.service';
import { GoogleEventTrackerService } from '../../core/services/google-event-tracker.service';
import { SnapEventTrackerService } from '../../core/services/snap-event-tracker.service';
import { TiktokEventTrackerService } from '../../core/services/tiktok-event-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartKey = 'cart';
  cartLength$ = new BehaviorSubject<number>(0);

  constructor() {
    this.cleanExpiredItems();
    this.cartLength$.next(this.getItems().length);
  }

  addItem(item: any): boolean {
    let currentCart = this.getFilteredItems(true);

    if (currentCart.length > 0) {
      return false;
    }

    const itemIndex = currentCart.findIndex(
      (cartItem) => cartItem.ProductId === item.ProductId
    );

    if (itemIndex > -1) {
      currentCart[itemIndex].quantity += 1;
    } else {
      const newItem = {
        ...item,
        quantity: 1,
        created: new Date().toISOString(),
      };
      currentCart.push(newItem);
    }

    GoogleEventTrackerService.trackEvent('add_to_cart', { item: item });
    FacebookPixelEventTrackerService.trackEvent('add_to_cart', { item: item });
    SnapEventTrackerService.trackEvent('ADD_CART', {
      price: item.FinalPrice,
      currency: 'SAR',
      item_ids: [`${item.ProductId}`],
      item_category: item.branch.name,
      number_items: item.quantity,
      uuid_c1: item.Guid,
    });
    TiktokEventTrackerService.trackEvent('AddToCart', {
      contents: [
        {
          content_id: `${item.ProductId}`,
          content_type: 'product',
          content_category: item.branch.name,
          content_name: item.ProductName,
          quantity: `${item.quantity}`,
          price: `${item.FinalPrice}`,
        },
      ],
      value: `${item.FinalPrice}`,
      currency: 'SAR',
    });

    localStorage.setItem(this.cartKey, JSON.stringify(currentCart));
    this.cartLength$.next(currentCart.length);
    return true;
  }

  getItems(includeCreatedDate = false): any[] {
    return this.getFilteredItems(includeCreatedDate);
  }

  getFilteredItems(includeCreatedDate = false): any[] {
    let items = localStorage.getItem(this.cartKey);
    if (!items) return [];

    let parsedItems: any[] = JSON.parse(items);
    const now = new Date();
    const oneHour = 60 * 60 * 1000; // milliseconds
    parsedItems = parsedItems.filter((item) => {
      const itemCreatedTime = new Date(item.created).getTime();
      return now.getTime() - itemCreatedTime < oneHour;
    });

    if (!includeCreatedDate) {
      return parsedItems.map(({ created, ...rest }) => rest);
    }
    return parsedItems;
  }

  cleanExpiredItems(): void {
    let currentCart: any[] = JSON.parse(
      localStorage.getItem(this.cartKey) || '[]'
    );
    const now = new Date();
    const oneHour = 60 * 60 * 1000;
    const filteredCart = currentCart.filter((item) => {
      const itemCreatedTime = new Date(item.created).getTime();
      return now.getTime() - itemCreatedTime < oneHour;
    });

    if (filteredCart.length !== currentCart.length) {
      localStorage.setItem(this.cartKey, JSON.stringify(filteredCart));
      this.cartLength$.next(filteredCart.length);
    }
  }

  removeItem(productId: number): void {
    let currentCart = this.getItems(true);
    const itemIndex = currentCart.findIndex(
      (cartItem) => cartItem.ProductId === productId
    );
    if (itemIndex > -1) {
      currentCart.splice(itemIndex, 1);
      localStorage.setItem(this.cartKey, JSON.stringify(currentCart));
    }
    this.cartLength$.next(currentCart.length);
  }

  clearCart(): void {
    localStorage.removeItem(this.cartKey);
    this.cartLength$.next(0);
  }

  increaseQuantity(productId: number): void {
    let currentCart = this.getItems(true);
    const itemIndex = currentCart.findIndex(
      (cartItem) => cartItem.ProductId === productId
    );

    if (itemIndex > -1) {
      currentCart[itemIndex].quantity += 1;
      localStorage.setItem(this.cartKey, JSON.stringify(currentCart));
    }
  }

  decreaseQuantity(productId: number): void {
    let currentCart = this.getItems(true);
    const itemIndex = currentCart.findIndex(
      (cartItem) => cartItem.ProductId === productId
    );

    if (itemIndex > -1 && currentCart[itemIndex].quantity > 1) {
      // Decrease the quantity only if it's more than 1
      currentCart[itemIndex].quantity -= 1;
    } else if (itemIndex > -1) {
      // If quantity is 1, remove the item from the cart
      currentCart.splice(itemIndex, 1);
    }

    localStorage.setItem(this.cartKey, JSON.stringify(currentCart));
  }

  totalTax(): number {
    const currentCart = this.getItems();
    let totalTax = 0;
    currentCart.forEach((item) => {
      totalTax += (item.Price - item.FinalPrice) * item.quantity;
    });
    return totalTax;
  }

  totalPrice(): number {
    const currentCart = this.getItems();
    let totalPrice = 0;
    currentCart.forEach((item) => {
      totalPrice += item.Price * item.quantity;
    });
    return totalPrice;
  }
}
