import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { checkoutApis } from '../util/checkout.apis';
import { Transaction } from '../util/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  customHttp = inject(CustomHttpClientService);

  beginTransaction(data: any[]) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.beginTransaction, { data: data })
      .pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Transaction();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      );
  }

  getTransaction(otps: string) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.getTransaction, {
        otps: otps,
      })
      .pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Transaction();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      );
  }

  getTransactionByPayment(paymentId: string) {
    return this.customHttp
      .get<{ status: boolean; transactions: Transaction[] }>(
        checkoutApis.getTransactionByPayment(paymentId)
      )
      .pipe(
        first(),
        map((res) => {
          if (res['status']) {
            for (let i = 0; i < res['transactions']?.length; i++) {
              const t2 = new Transaction();
              Object.assign(t2, res['transactions'][i]);
              res['transactions'][i] = t2;
            }
            return res;
          }
          return null;
        })
      );
  }

  checkoutTransaction(transactions: Transaction[], formData: any[]) {
    return this.customHttp
      .post<any>(checkoutApis.checkoutTransaction, {
        transactions: transactions,
        formData: formData,
      })
      .pipe(first());
  }

  checkoutTabbyTransaction(transactions: Transaction[], formData: any[]) {
    return this.customHttp
      .post<any>(checkoutApis.checkoutTabbyTransaction, {
        transactions: transactions,
        formData: formData,
      })
      .pipe(first());
  }

  checkoutTamaraTransaction(transactions: Transaction[], formData: any[]) {
    return this.customHttp
      .post<any>(checkoutApis.checkoutTamaraTransaction, {
        transactions: transactions,
        formData: formData,
      })
      .pipe(first());
  }

  getTabbyTransaction(paymentId: string) {
    return this.customHttp
      .post<any>(checkoutApis.getTabbyTransaction, {
        payment_id: paymentId,
      })
      .pipe(first());
  }

  completeTransaction(otps: number, id: string) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.completeTransaction, {
        otps: otps,
        id: id,
      })
      .pipe(first());
  }

  completeTabbyTransaction(payment_id: string) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.completeTabbyTransaction, {
        payment_id: payment_id,
      })
      .pipe(first());
  }

  completeTamaraTransaction(orderId: string) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.completeTamaraTransaction, {
        orderId: orderId,
      })
      .pipe(first());
  }

  completeAppleTransaction(
    transactions: Transaction[],
    formData: any[],
    payment: any[]
  ) {
    return this.customHttp
      .post<any>(checkoutApis.completeAppleTransaction, {
        transactions: transactions,
        formData: formData,
        payment: payment,
      })
      .pipe(first());
  }

  applyCoupon(transactions: Transaction[], coupon: string) {
    return this.customHttp
      .post<Transaction[]>(checkoutApis.applyCoupon, {
        transactions: transactions,
        coupon: coupon,
      })
      .pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Transaction();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      );
  }

  checkCardValidity(branch: string, cardNumber: string) {
    return this.customHttp
      .get<any>(checkoutApis.checkCardValidity(branch, cardNumber))
      .pipe(
        first(),
        map((res) => {
          if (res != null && res.length > 0) {
            return res[0]['CustomerName'];
          }
          return null;
        })
      );
  }

  validateEmail(email: string) {
    return this.customHttp
      .get<any>(checkoutApis.validateEmail(email))
      .pipe(first());
  }

  pingTicket(paymentId: string, otp: string) {
    return this.customHttp
      .get<any>(checkoutApis.pingTicket(paymentId, otp))
      .pipe(first());
  }
}
