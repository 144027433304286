import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [NgStyle, NgTemplateOutlet, NgIf, NgClass, NgxIkImageCdnComponent],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  @Input() headerImage: string | undefined;
  @Input() headerTitle: string | undefined;
  @Input() headerIcon: string | undefined;
  @Input() color: string | undefined;
  @Input() isEvent: boolean = false;
  @ContentChild('cardContent') cardContent: TemplateRef<any> | undefined;
  @ContentChild('cardFooter') cardFooter: TemplateRef<any> | undefined;

  convertColorWithOpacity(color: string, opacity: number): string {
    if (color.startsWith('#')) {
      let r = parseInt(color.slice(1, 3), 16);
      let g = parseInt(color.slice(3, 5), 16);
      let b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else if (color.startsWith('rgb')) {
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }
    return color;
  }
}
