import { Branch } from '../../branch/util/branch.model';

export class Product {
  FinalPrice!: number;
  BasePrice!: number;
  UserRolePrice!: number;
  MemberShipPrice!: number;
  TransactionProfilePrice!: number;
  PromotionPrice!: number;
  PriceType!: string;
  Tickets!: number;
  FaceValue!: number;
  DisplayGroup!: string;
  VipCard!: string;
  TicketAllowed!: string;
  TaxInclusivePrice!: string;
  InventoryProductCode!: string;
  ExpiryDate!: string;
  AutoCheckOut!: string;
  CheckInFacilityId!: number;
  MaxCheckOutAmount!: number;
  CustomDataSetId!: number;
  CardTypeId!: number;
  OverridePrintTemplateId!: number;
  StartDate!: string;
  ButtonColor!: string;
  MinimumUserPrice!: number;
  TextColor!: string;
  Font!: string;
  Modifier!: string;
  EmailTemplateId!: number;
  MaximumTime!: number;
  MinimumTime!: number;
  CardValidFor!: number;
  AdditionalPrice!: number;
  AdditionalTaxInclusive!: string;
  AdditionalTaxId!: number;
  SegmentCategoryId!: number;
  CardExpiryDate!: string;
  ProductDisplayGroupFormatId!: number;
  EnableVariableLockerHours!: boolean;
  CategoryName!: string;
  LicenseType!: string;
  CardSale!: string;
  ZoneCode!: string;
  LockerMode!: string;
  TaxName!: string;
  UsedInDiscounts!: string;
  CreditPlusConsumptionId!: number;
  MapedDisplayGroup!: number;
  LinkChildCard!: boolean;
  ZoneId!: number;
  LockerExpiryInHours!: number;
  LockerExpiryDate!: string;
  HsnSacCode!: string;
  MembershipId!: number;
  IsSellable!: boolean;
  ServiceCharge!: number;
  PackingCharge!: number;
  ProductId!: number;
  ProductName!: string;
  ProductType!: string;
  Description!: string;
  ProductTypeId!: number;
  CategoryId!: number;
  Price!: number;
  SortOrder!: number;
  SiteId!: number;
  AutoGenerateCardNumber!: string;
  QuantityPrompt!: string;
  TrxRemarksMandatory!: string;
  OnlyForVIP!: string;
  AllowPriceOverride!: string;
  RegisteredCustomerOnly!: string;
  ManagerApprovalRequired!: string;
  VerifiedCustomerOnly!: string;
  ExternalSystemReference!: string;
  MinimumQuantity!: number;
  CardCount!: number;
  TrxHeaderRemarksMandatory!: boolean;
  ImageFileName!: string;
  AdvancePercentage!: number;
  AdvanceAmount!: number;
  WaiverRequired!: string;
  InvokeCustomerRegistration!: boolean;
  DisplayInPOS!: string;
  TaxId!: number;
  TaxPercentage!: number;
  WaiverSetId!: number;
  LoadToSingleCard!: boolean;
  IsGroupMeal!: string;
  IsSystemProduct!: boolean;
  IsActive!: boolean;
  POSTypeId!: number;
  NotificationTagProfileId!: number;
  IssueNotificationDevice!: string;
  IsRecommended!: boolean;
  SearchDescription!: string;
  MaxQtyPerDay!: number;
  AvailableUnits!: number;
  WebDescription!: string;
  TranslatedProductName!: string;
  TranslatedProductDescription!: string;
  Guid!: string;

  ComboProductContainerDTOList!: any;
  ProductModifierContainerDTOList!: any;
  ProductsDisplayGroupContainerDTOList!: any;
  CustomerProfilingGroupContainerDTO!: any;
  InventoryItemContainerDTO!: any;
  ProductSubscriptionContainerDTO!: any;
  UpsellOffersContainerDTOList!: any;
  CrossSellProductsContainerDTOList!: any;
  CustomDataContainerDTOList!: any;

  StartTime!: string;
  ExpiryTime!: string;
  OrderTypeId!: number;
  Credits!: number;
  Courtesy!: number;
  Bonus!: number;
  Time!: number;
  ServiceChargeIsApplicable!: boolean;
  ServiceChargePercentage!: number;
  GratuityIsApplicable!: boolean;
  GratuityPercentage!: number;
  MaximumQuantity!: number;
  PauseType!: number;
  CustomerProfilingGroupId!: number;
  AgeUpperLimit!: number;
  AgeLowerLimit!: number;
  branch!: Branch;

  localProduct!: {
    description: string;
    full_description: string;
    id: number;
    image: string;
    images: string[];
    is_discount: boolean;
    is_recommended: boolean;
    is_visible: boolean;
    max_age: number;
    min_age: number;
    name: string;
    product_id: number;
    site_id: string;
    terms: string;
    uuid: string;
    whats_included: string;
  };
}
