<div class="content">
  <div class="content-inner">
    <div class="container">
      <div class="attractions section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <!-- <h1>
                {{ "booking.thankyou.reserved_successfully" | apiTranslation }}
              </h1> -->
            </div>
            <div class="section-subtitle">
              <!-- <h2>
                Experience the world's Fastest Vertical Launch Rollercoaster in
                Dubai for the thrill of your life!
              </h2> -->
            </div>
          </div>
        </div>

        <div class="checkout-container">
          <div
            class="checkout-details"
            [ngClass]="{
              failure: paymentFailed == true,
              success: paymentFailed == false
            }"
          >
            <div class="checkout-user-info">
              <ng-container
                *ngIf="transactions$ | async as transactions"
              ></ng-container>
              <div *ngIf="isLoading" class="loading-wrapper">
                <i class="pi pi-spin pi-spinner"></i>
                {{ "booking.thankyou.loading" | apiTranslation }}
              </div>
              <div *ngIf="!isLoading">
                <div *ngIf="transactions">
                  <h2>
                    {{
                      "booking.thankyou.reserved_successfully" | apiTranslation
                    }}
                  </h2>
                  <ng-container
                    *ngFor="transaction; of: transactions; index as i"
                  >
                    <button
                      pButton
                      class="mx-2 gap-2"
                      [disabled]="
                        ticketPaths[transactions[i].TransactionOTP!] == null
                      "
                      (click)="
                        downloadTicket(
                          ticketPaths[transactions[i].TransactionOTP!]!
                        )
                      "
                    >
                      <ng-container
                        *ngIf="
                          ticketPaths[transactions[i].TransactionOTP!] !== null;
                          else loading
                        "
                      >
                        {{ "booking.thankyou.download_ticket" | translate }}
                        - {{ transactions[i].branchName }}
                        <i class="pi pi-download"></i>
                      </ng-container>
                      <ng-template #loading>
                        {{
                          "booking.thankyou.generating_ticket" | translate
                        }}...
                        <i class="mi-2 pi pi-spin pi-spinner"></i>
                      </ng-template>
                    </button>
                    <br /><br />
                  </ng-container>
                </div>
                <div *ngIf="!transactions">
                  <h2>خطأ في عملية الدفع</h2>
                  <p>{{ message }}</p>
                  <br />
                  <a href="/checkout">
                    {{ "booking.thankyou.back_to_checkout" | translate }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- </ng-container> -->
      </div>
      <div class="section">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
