<div class="content">
  <div class="content-inner">
    <div class="container">
      <div class="section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <h1>
                {{ "booking.cart.my_cart" | apiTranslation }} ({{
                  cart.length
                }})
              </h1>
            </div>
            <div class="section-subtitle">
              <!-- <h2>
                Experience the world's Fastest Vertical Launch Rollercoaster in
                Dubai for the thrill of your life!
              </h2> -->
            </div>
          </div>
        </div>
        <div class="checkout-container">
          <div class="tickets">
            <div
              *ngFor="let product of cart; index as i"
              class="ticket"
              [ngClass]="{
                error: i == errorIndex
              }"
            >
              <div class="ticket-header">
                <div class="ticket-icon">
                  <app-ngx-ik-image-cdn
                    [path]="product.branch.icon"
                  ></app-ngx-ik-image-cdn>
                </div>
                <div class="ticket-desctiption">
                  <div class="ticket-title">
                    {{ product.localProduct.name }}
                  </div>
                  <div class="ticket-subtitle">{{ product.branch.name }}</div>
                </div>
              </div>
              <div class="ticket-body">
                <div class="content">
                  <div [innerHTML]="product.localProduct.description"></div>
                </div>
                <div class="actions">
                  <div
                    class="action"
                    (click)="removeItem(product.ProductId, i)"
                  >
                    <i class="pi pi-trash"></i>
                  </div>
                  <div
                    class="action"
                    (click)="incrementItem(product.ProductId)"
                  >
                    <div class="outline-circle">
                      <i class="pi pi-plus"></i>
                    </div>
                  </div>
                  <p>{{ product.quantity }}</p>
                  <div
                    class="action"
                    (click)="decrementItem(product.ProductId)"
                  >
                    <div class="outline-circle">
                      <i class="pi pi-minus"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="details">
                <div class="form-group">
                  <div class="d-flex card-number-container">
                    <div class="item-details">
                      <button
                        pButton
                        pRipple
                        [label]="
                          'branches.products.product.view_details'
                            | apiTranslation
                        "
                        icon="pi pi-arrow-up-left"
                        iconPos="right"
                        [routerLink]="
                          '/view/' + product.localProduct.product_id
                        "
                      ></button>
                      <div class="input-holder">
                        <div class="input-holder">
                          <label for="cardNumber">{{
                            "booking.cart.enter_card_number" | apiTranslation
                          }}</label>
                          <input
                            id="cardNumber"
                            name="cardNumber"
                            type="text"
                            appAllowNumbersAndLetters
                            [(ngModel)]="
                              cardNumberInput[product.branch.siteId.toString()]
                            "
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              onCardNumberChange(
                                product.branch.siteId.toString(),
                                $event
                              )
                            "
                            [ngClass]="{
                      valid: cardNumberStatus[product.branch.siteId.toString()] == true,
                      invalid: cardNumberStatus[product.branch.siteId.toString()] == false,
                    }"
                          />
                          <p
                            class="cardName"
                            *ngIf="
                              cardNumberStatus[
                                product.branch.siteId.toString()
                              ] == true
                            "
                          >
                            {{ "booking.cart.product.name" | apiTranslation }}
                            {{
                              cardNumberName[product.branch.siteId.toString()]
                            }}
                          </p>
                          <p
                            class="cardName error"
                            *ngIf="
                              cardNumberStatus[
                                product.branch.siteId.toString()
                              ] == false
                            "
                          >
                            {{
                              "booking.cart.card_name_error" | apiTranslation
                            }}
                          </p>
                        </div>
                        <div
                          class="mt-3"
                          *ngIf="ownCards && ownCards[product.SiteId]"
                        >
                          <label for="cardNumber">{{
                            "booking.cart.choose_from_yours" | translate
                          }}</label>
                          <div class="cards-container flex flex-wrap mt-2">
                            <ng-container
                              *ngFor="let card of ownCards[product.SiteId]"
                            >
                              <div
                                class="card-item"
                                (click)="
                                  cardNumberInput[
                                    product.branch.siteId.toString()
                                  ] = card;
                                  onCardNumberChange(
                                    product.branch.siteId.toString(),
                                    card
                                  )
                                "
                              >
                                <p>{{ card }}</p>
                              </div></ng-container
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-info">
                      <app-ngx-ik-image-cdn
                        path="images/malahi-card.png"
                      ></app-ngx-ik-image-cdn>
                      <p>
                        {{ "booking.cart.card_number_info" | apiTranslation }}
                      </p>
                    </div>
                    <!-- <svg width="70" height="35" viewBox="0 0 32 21">
                      <g fill="currentColor" fill-rule="evenodd">
                        <path
                          d="M21.68 2H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2v-7.53a5.98 5.98 0 0 1-3 1.45V13c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z"
                          opacity=".4"
                        ></path>
                        <path
                          d="M19.34 5H0v3h19.08a6.04 6.04 0 0 1 .26-3z"
                          opacity=".6"
                        ></path>
                        <path
                          d="M25 14a7 7 0 1 1 0-14 7 7 0 0 1 0 14zm-2.78-9.9h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33 0-.94-.83-1.58-2.03-1.58a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92h-2.02v3.15c.4-.1.8-.17 1.2-.17z"
                        ></path>
                      </g>
                    </svg> -->
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="ticket-footer">
                <div class="ticket-info">
                  <!-- <h1>Single Day</h1> -->
                  <!-- <h1>Height 130+ cm</h1> -->
                </div>
                <div class="ticket-price">
                  <h1>{{ product.Price }} SAR</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="bill-info-container">
            <div class="bill-info">
              <div class="bill">
                <h2>{{ "booking.cart.amount" | apiTranslation }}</h2>
                <div class="bill-item" *ngFor="let product of cart">
                  <div class="product-name">
                    <p class="desc">
                      {{ product.localProduct.name }}
                    </p>
                    <span class="item-count-span" *ngIf="product.quantity > 1">
                      <span>x </span>
                      <span>{{ product.quantity }}</span>
                    </span>
                  </div>
                  <p class="price">
                    {{
                      product.FinalPrice * product.quantity | number : "1.2-2"
                    }}
                    SAR
                  </p>
                </div>
                <div class="bill-item">
                  <p class="desc">
                    15% {{ "booking.cart.vat" | apiTranslation }}
                  </p>
                  <p class="price">{{ totalTax() | number : "1.2-2" }} SAR</p>
                </div>
                <div class="divider"></div>
                <div class="bill-item">
                  <h3>{{ "booking.cart.subtotal_price" | apiTranslation }}</h3>
                  <h3 class="total-price">
                    {{ totalPrice() | number : "1.2-2" }} SAR
                  </h3>
                </div>
              </div>
              <div class="checkout-btn">
                <button
                  (click)="goToCheckout()"
                  [disabled]="!cart.length || checkCardLoading"
                  [ngClass]="{
                    disabled: !cart.length
                  }"
                >
                  <ng-container *ngIf="!checkCardLoading; else loadingCard">
                    {{ "booking.cart.checkout" | apiTranslation }}
                  </ng-container>
                  <ng-template #loadingCard>
                    <i class="pi pi-spin pi-spinner"></i>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>

<p-toast [position]="'top-center'"></p-toast>
