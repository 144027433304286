import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(content: string, truncateRows: number = 2): string {
    if (!content) return '';

    const rows = content.split('<br>');
    const truncatedRows = rows.slice(0, truncateRows);
    if (rows.length > truncateRows) {
      truncatedRows[truncatedRows.length - 1] += '...';
    }
    return truncatedRows.join('<br>');
  }
}
