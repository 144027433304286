import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAllowNumbersAndLetters]',
  standalone: true,
})
export class AllowNumbersAndLettersDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let filtered = input.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    input.value = filtered.slice(0, 8);
  }
}
