import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LanguageService } from './core/services/language-service/language.service';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HeaderComponent } from './core/widgets/header/header.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgIf, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  languageService = inject(LanguageService);
  _cd = inject(ChangeDetectorRef);
  http = inject(HttpClient);
  newComer = true;

  ngOnInit(): void {
    this.newComer = localStorage.getItem('ar') == null;

    if (this.newComer == true) {
      const langRequest = this.http.get('assets/apiTrans.json');

      langRequest.subscribe((res: any) => {
        localStorage.setItem('ar', JSON.stringify(res['ar']));
        localStorage.setItem('en', JSON.stringify(res['en']));
        this.newComer = false;
        this._cd.markForCheck();
      });
    }

    this.languageService.getTranslations().subscribe((res: any) => {
      localStorage.setItem('ar', JSON.stringify(res['ar']));
      localStorage.setItem('en', JSON.stringify(res['en']));
      this.newComer = false;
      this._cd.markForCheck();
    });
  }
}
