<div class="content">
  <div class="container">
    <div class="section flex flex-column align-items-center">
      <div class="section-header">
        <div class="section-title">
          <h1>
            {{ "booking.user.log_to_your_account" | translate }}
          </h1>
        </div>
      </div>
      <div class="section-content">
        <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
          <p-card [style]="{ width: '360px' }">
            <div *ngIf="message" class="message-container success mb-4">
              <p>{{ message | translate }}</p>
            </div>
            <div class="flex flex-column gap-3">
              <div class="flex flex-column gap-2 w-full">
                <label for="email">{{ "auth.email" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-at"></i>
                  </p-inputGroupAddon>
                  <input
                    type="email"
                    pInputText
                    placeholder="name@mail.com"
                    formControlName="email"
                    [ngClass]="{
                      'ng-invalid ng-dirty': signInForm.get('email')?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>

              <div class="flex flex-column gap-2 w-full">
                <label for="password">{{ "auth.password" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-key"></i>
                  </p-inputGroupAddon>
                  <input
                    type="password"
                    pInputText
                    placeholder="Password"
                    formControlName="password"
                    [ngClass]="{
                      'ng-invalid ng-dirty': signInForm.get('password')?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>
              <!-- Forgot Password -->
              <a
                routerLink="/forgot-password"
                class="forgot-password flex justify-content-end"
                >{{ "booking.cart.forgot_password" | translate }}</a
              >
            </div>
            <p class="error-txt mt-4" *ngIf="errorMessage">
              <i class="pi pi-exclamation-circle"></i>
              {{ errorMessage | translate }}
            </p>
            <p class="mt-2 text-center" *ngIf="showResendEmail">
              <a
                (click)="resendVerification()"
                style="cursor: pointer; text-decoration: underline"
              >
                {{ "user.resend-verification" | translate }}</a
              >
            </p>
            <ng-template pTemplate="footer">
              <div class="flex gap-3 mt-1">
                <p-button
                  label="{{ 'booking.user.sign_in' | translate }}"
                  class="w-full"
                  styleClass="w-full"
                  type="submit"
                  [loading]="loading"
                />
              </div>
              <!-- Dont have an account? Sign Up -->
              <p class="mt-4 text-center">
                {{ "booking.cart.dont_have_account" | translate }}
                <a routerLink="/register">{{
                  "booking.cart.sign_up" | translate
                }}</a>
              </p>
            </ng-template>
          </p-card>
        </form>

        <a routerLink="/forgot-password" class="forgot-password">{{
          "booking.cart.forgot_password" | apiTranslation
        }}</a>
      </div>
    </div>
    <div class="section">
      <app-footer></app-footer>
    </div>
  </div>
</div>

<p-toast [position]="'top-center'"></p-toast>
