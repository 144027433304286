<div class="content">
  <div class="carousel-container">
    <div class="carousel">
      <ng-container *ngIf="branch$ | async as branch; else bannerLoading">
        <ng-container *ngIf="branch.is_video; else bannersTemplate">
          <video width="100%" height="100%" autoplay [muted]="'muted'" loop>
            <source [src]="baseStorage + '/' + branch.video" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ng-container>
        <ng-template #bannersTemplate>
          <ng-container *ngIf="banners$ | async as banners; else bannerLoading">
            <div class="carousel-images">
              <div
                *ngFor="let banner of banners; let i = index"
                class="carousel-image"
                [ngStyle]="{ '--bg-image': 'url(' + banner + ')' }"
                [ngClass]="{
                  'carousel-image-inactive': i !== activeBannerIndex
                }"
              >
                <!-- TODO: return alt from backend -->
                <app-ngx-ik-image-cdn [path]="banner"></app-ngx-ik-image-cdn>
              </div>
            </div>
            <div class="images-indicator">
              <div class="d-flex jcc">
                <div
                  *ngFor="let banner of banners; let i = index"
                  class="indicator"
                  [ngClass]="{ active: i === activeBannerIndex }"
                  (click)="goToBanner(i)"
                ></div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #bannerLoading>
        <p-skeleton width="100%" height="60vh"></p-skeleton>
      </ng-template>
    </div>
  </div>
</div>
<div class="content page-content">
  <div class="container">
    <div class="section mt-5 mb-0">
      <div class="section-header mb-0">
        <div class="section-description">
          <div class="section-title">
            <h1>{{ "home.branches.title" | apiTranslation }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="branch-slider">
    <div class="branch-slider-container">
      <div class="branch-slider-content">
        <div class="branch-slider-items" #branchSlider>
          <ng-container
            *ngIf="branches$ | async as branches; else branchesLoading"
          >
            <div
              class="branch-slider-item"
              *ngFor="let branch of branches"
              (click)="goToBranch(branch.siteId)"
              [ngClass]="{
                active: branch.siteId === currentBranch.siteId
              }"
              [ngStyle]="{
                'box-shadow':
                  branch.siteId === currentBranch.siteId
                    ? '0 0 2px 1px ' + branch.color
                    : 'none',
                background:
                  branch.siteId === currentBranch.siteId
                    ? 'linear-gradient(45deg, #00000000, ' +
                      convertColorWithOpacity(
                        branch.color ?? 'rgb(75, 0, 130)',
                        0.6
                      ) +
                      ')'
                    : '#242526'
              }"
            >
              <div class="branch-slider-item-image">
                <app-ngx-ik-image-cdn
                  width="30"
                  [path]="branch.icon"
                ></app-ngx-ik-image-cdn>
              </div>
              <div class="branch-slider-item-title">
                <h1>{{ branch.name }}</h1>
              </div>
            </div>
          </ng-container>
          <ng-template #branchesLoading>
            <p-skeleton
              [shape]="'circle'"
              width="40px"
              height="40px"
            ></p-skeleton>
            <div style="width: 1rem"></div>
            <p-skeleton width="100px" height="40px"></p-skeleton>
          </ng-template>
        </div>
        <!-- <div class="scroll-button scroll-button-right">
          <button (click)="scrollBranchSlider('right')">
            <i class="pi pi-chevron-{{ 'right' }}"></i>
          </button>
        </div>
        <div class="scroll-button scroll-button-left">
          <button (click)="scrollBranchSlider('left')">
            <i class="pi pi-chevron-{{ 'left' }}"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="content-inner">
    <div class="container">
      <div class="attractions section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <h1>{{ "branches.title" | apiTranslation }}</h1>
            </div>
            <div class="section-subtitle">
              <h2>{{ "branches.description" | apiTranslation }}</h2>
            </div>
          </div>
        </div>
        <div class="section-list tickets-list">
          <ng-container
            *ngIf="products$ | async as products; else productsLoading"
          >
            <app-product-card
              *ngFor="let product of products; let pIndex = index"
              [product]="product"
              [active]="hoveredProduct === pIndex"
              (mouseenter)="hoveredProduct = pIndex"
              (mouseleave)="hoveredProduct = -1"
            >
              <!-- [routerLink]="'/view/' + product.localProduct.product_id" -->
            </app-product-card>
          </ng-container>
          <ng-template #productsLoading>
            <p-skeleton
              *ngFor="let _ of [].constructor(3)"
              width="100%"
              height="400px"
            ></p-skeleton>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="branch$ | async as branch">
        <div class="information-section section">
          <div class="section-header">
            <div class="header-title">
              <h1>{{ "branches.information.info_title" | apiTranslation }}</h1>
            </div>
          </div>
          <div class="d-flex jcb aic">
            <div class="section-list enquiries-list">
              <div class="category chip selected">
                <h1>{{ "branches.information.info_chip" | apiTranslation }}</h1>
              </div>
            </div>
          </div>
          <div class="information">
            <div class="contact">
              <div class="contact-header">
                {{ "branches.information.address_title" | apiTranslation }}
              </div>
              <div class="contact-body">
                <div class="info-item location">
                  <div class="info-item-icon">
                    <i class="pi pi-map-marker"></i>
                  </div>
                  <div class="info-item-description">
                    <div class="info-item-title">{{ branch.address1 }}</div>
                    <div class="info-item-content">{{ branch.address2 }}</div>
                  </div>
                </div>
                <div class="info-item call-us">
                  <div class="info-item-icon">
                    <i class="pi pi-phone"></i>
                  </div>
                  <div class="info-item-description">
                    <div class="info-item-title">
                      {{ "branches.information.call_title" | apiTranslation }}
                    </div>
                    <div class="info-item-content">
                      {{
                        "branches.information.call_subtitle" | apiTranslation
                      }}
                      {{ branch.call_us }}
                    </div>
                  </div>
                </div>
                <div class="info-item email-us">
                  <div class="info-item-icon">
                    <i class="pi pi-at"></i>
                  </div>
                  <div class="info-item-description">
                    <div class="info-item-title">
                      {{ "branches.information.email_title" | apiTranslation }}
                    </div>
                    <div class="info-item-content">
                      {{ branch.email_us }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="map">
              <google-map width="100%" height="300" [options]="mapOptions">
                <map-marker
                  [options]="markerOptions"
                  [position]="markerPosition"
                ></map-marker>
              </google-map>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="section footer-section">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
