import { Injectable } from '@angular/core';

declare const fbq: any;

@Injectable()
export class FacebookPixelEventTrackerService {
  constructor() {}

  public static trackEvent(key: string, properties: Object) {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('track', key, properties);
  }
}
