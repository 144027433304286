import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FooterComponent } from '../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../core/widgets/header/header.component';
import { ContentViewerService } from '../data-access/content-viewer.service';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-content-viewer',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, NgIf, AsyncPipe, SkeletonModule],
  templateUrl: './content-viewer.component.html',
  styleUrl: './content-viewer.component.scss',
})
export class ContentViewerComponent implements OnInit {
  service = inject(ContentViewerService);
  route = inject(ActivatedRoute);
  content$: Observable<any> | undefined;

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.route.paramMap.subscribe((params) => {
      const slug = params.get('slug');
      if (slug) {
        this.content$ = this.service.getContent(slug);
      }
    });
  }
}
