<ng-container *ngIf="pageLoading">
  <p-skeleton width="100%" height="100px"></p-skeleton>
  <p-skeleton width="100%" height="100px"></p-skeleton>
  <p-skeleton width="100%" height="100px"></p-skeleton>
</ng-container>
<ng-container *ngIf="!pageLoading">
  <div class="block-section">
    <div class="block-content">
      <div class="lg:px-8 md:px-6 px-4 py-6">
        <div class="grid">
          <div
            *ngFor="let card of cards"
            class="col-12 md:col-6 xl:col-4 p-3"
          >
            <div
              class="branch-card border-round p-4"
              [ngStyle]="{
                background:
                  'linear-gradient(45deg, #090a0a, ' +
                  branches[card.semnox_details['SiteId'][0]].color +
                  '6e)',
                'box-shadow':
                  '0 0 18px ' +
                  branches[card.semnox_details['SiteId'][0]].color +
                  '45'
              }"
            >
              <app-ngx-ik-image-cdn
                [path]="branches[card.semnox_details['SiteId'][0]].icon"
                class="b-img"
              ></app-ngx-ik-image-cdn>
              <app-ngx-ik-image-cdn
                *ngIf="card.semnox_details['SiteId'].length > 1"
                [path]="branches[card.semnox_details['SiteId'][1]].icon"
                class="b-img second"
              ></app-ngx-ik-image-cdn>
              <div class="flex surface-border pb-4">
                <app-ngx-ik-image-cdn
                  *ngIf="card.semnox_details['SiteId'].length == 1"
                  [path]="
                    branches[card.semnox_details['SiteId'][0]].icon
                  "
                ></app-ngx-ik-image-cdn>
                <div class="flex flex-column align-items-start z-2">
                  <span class="text-xl text-900 font-medium mb-1">{{
                    card.card_number
                  }}</span>
                  <span class="text-600 font-medium mb-2"
                    >{{ card.semnox_details["CustomerName"] }}
                  </span>
                  <span class="text-600 font-medium mb-2"
                    ><ng-container
                      *ngFor="
                        let item of card.semnox_details['SiteId'];
                        let i = index
                      "
                      >{{ branches[item].name
                      }}{{
                        i + 1 < card.semnox_details["SiteId"].length
                          ? ", "
                          : ""
                      }}
                    </ng-container>
                  </span>
                  <div class="flex gap-2">
                    <span
                      class="bg-indigo-400 gap-1 text-white border-round inline-flex py-1 px-2 text-sm"
                      ><i class="pi pi-wallet me-1"></i
                      >{{
                        card.semnox_details["TotalPoints"]
                          | number : "1.2-2"
                      }}</span
                    >
                    <span
                      class="bg-blue-400 gap-1 text-white border-round inline-flex py-1 px-2 text-sm"
                      ><i class="pi pi-ticket me-1"></i
                      >{{
                        card.semnox_details["TotalTickets"]
                          | number : "1.2-2"
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="flex justify-content-between border-top-1 pt-4 v"
              >
                <button
                  pbutton=""
                  pripple=""
                  label="View"
                  icon="pi pi-search"
                  (click)="op.toggle($event)"
                  class="p-element p-ripple p-button-outlined p-button-secondary w-6 mx-2 p-button p-component z-2"
                >
                  <span
                    class="p-button-icon p-button-icon-left pi pi-search mx-1 px-1"
                    aria-hidden="true"
                  ></span
                  ><span class="p-button-label">{{
                    "View" | translate
                  }}</span
                  ><span class="p-ink"></span>
                </button>

                <p-overlayPanel #op>
                  <div class="flex flex-column w-18rem">
                    <div>
                      <span class="font-medium text-900 block mb-2">{{
                        card.semnox_details["CustomerName"]
                      }}</span>
                    </div>
                    <div>
                      <span class="font-medium text-900 block mb-2"
                        >{{ "booking.user.card_number" | translate }}:
                        {{ card.card_number }}</span
                      >
                    </div>
                    <div>
                      <span class="font-medium text-900 block mb-2"
                        >{{ "branches.branches" | translate }}:
                        <ng-container
                          *ngFor="
                            let item of card.semnox_details['SiteId'];
                            let i = index
                          "
                          >{{ branches[item].name
                          }}{{
                            i + 1 < card.semnox_details["SiteId"].length
                              ? ", "
                              : ""
                          }}
                        </ng-container>
                      </span>
                    </div>
                    <hr />
                    <div>
                      <span class="font-medium text-900 block mb-2"
                        >{{ "profile.total_points" | translate }}:
                        {{
                          card.semnox_details["TotalPoints"]
                            | number : "1.2-2"
                        }}</span
                      >
                    </div>
                    <div>
                      <span class="font-medium text-900 block mb-2"
                        >{{ "profile.total_tickets" | translate }}:
                        {{
                          card.semnox_details["TotalTickets"]
                            | number : "1.2-2"
                        }}</span
                      >
                    </div>
                  </div>
                </p-overlayPanel>

                <button
                  pbutton=""
                  pripple=""
                  label="Remove"
                  icon="pi pi-user-plus"
                  class="p-element p-ripple p-button-outlined p-button-secondary w-6 mx-2 p-button p-component z-2"
                  (click)="confirmDeleteCard($event, card.id)"
                >
                  <span
                    class="p-button-icon p-button-icon-left pi pi-trash mx-1 px-1"
                    aria-hidden="true"
                  ></span
                  ><span class="p-button-label">{{
                    "Remove" | translate
                  }}</span
                  ><span class="p-ink"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 xl:col-4 p-3">
            <div
              class="branch-card add border-round p-4"
              (click)="showDialog()"
            >
              <div class="icon"><i class="pi pi-plus"></i></div>
              <p>{{ "booking.user.add_card" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<p-toast [position]="'top-center'"></p-toast>
<p-confirmDialog />

<p-dialog
  header="{{ 'booking.user.add_card' | translate }}"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '25rem' }"
>
  <span class="p-text-secondary block mb-5">{{
    "booking.user.add_card_desc" | translate
  }}</span>

  <div class="flex align-items-center gap-2 mb-5">
    <label for="card_number" class="font-semibold w-6rem">{{
      "booking.user.card_number" | translate
    }}</label>
    <input
      pInputText
      appAllowNumbersAndLetters
      [(ngModel)]="cardNumber"
      [ngModelOptions]="{ standalone: true }"
      id="text"
      class="flex-auto"
      autocomplete="off"
    />
  </div>
  <p class="error-txt mb-3">{{ addCardError | translate }}</p>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="{{ 'Cancel' | translate }}"
      severity="secondary"
      (onClick)="visible = false"
    />
    <p-button
      [disabled]="!canAddCard()"
      [loading]="loading"
      label="{{ 'Save' | translate }}"
      (onClick)="addCard()"
    />
  </div>
</p-dialog>
