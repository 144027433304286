<ng-container *ngIf="pageLoading">
  <p-skeleton width="100%" height="100px"></p-skeleton>
  <p-skeleton width="100%" height="100px"></p-skeleton>
  <p-skeleton width="100%" height="100px"></p-skeleton>
</ng-container>
<ng-container *ngIf="!pageLoading">
  <div class="p-2 flex-auto xl:ml-5"></div>
  <div class="text-900 font-semibold text-lg mt-3">
    {{ "profile.personal_info" | translate }}
  </div>
  <p-divider class="p-element"></p-divider>
  <form [formGroup]="profileForm" (ngSubmit)="updateMyInfo()">
    <div class="flex gap-5 flex-column-reverse md:flex-row">
      <div class="flex flex-wrap">
        <!-- Name -->
        <div class="mb-2 md:col-6 sm:col-12">
          <label for="name">{{ "auth.name" | translate }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-user"></i>
            </p-inputGroupAddon>
            <input
              type="text"
              pInputText
              placeholder="Name"
              formControlName="name"
              [ngClass]="{
                'ng-invalid ng-dirty': profileForm.get('name')?.invalid
              }"
              required
            />
          </p-inputGroup>
        </div>

        <!-- Phone number -->
        <div class="mb-2 md:col-6 sm:col-12">
          <label for="phone_number">{{ "auth.phone" | translate }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-phone"></i>
            </p-inputGroupAddon>
            <ngx-lib-phone-input
              dir="ltr"
              formControlName="phone_number"
              maxLength="15"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  profileForm.get('phone_number')?.invalid ||
                  errors.hasOwnProperty('phone_number')
              }"
            ></ngx-lib-phone-input>
          </p-inputGroup>
        </div>

        <!-- Email -->
        <div class="mb-2 md:col-6 sm:col-12">
          <label for="email">{{ "auth.email" | translate }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-at"></i>
            </p-inputGroupAddon>
            <input
              type="email"
              pInputText
              placeholder="name@mail.com"
              formControlName="email"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  profileForm.get('email')?.invalid ||
                  errors.hasOwnProperty('email')
              }"
              required
            />
          </p-inputGroup>
        </div>

        <!-- Date of birth -->
        <div class="mb-2 md:col-6 sm:col-12">
          <label for="date_of_birth">{{ "auth.dob" | translate }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-calendar"></i>
            </p-inputGroupAddon>
            <p-calendar
              [showIcon]="false"
              [showButtonBar]="false"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="yearRange"
              [dateFormat]="dateFormat"
              formControlName="date_of_birth"
              [ngClass]="{
                'ng-invalid ng-dirty': profileForm.get('date_of_birth')?.invalid
              }"
              required
            ></p-calendar>
          </p-inputGroup>
        </div>
      </div>
    </div>
    <div class="flex gap-3 mt-1 mb-3">
      <div class="message-container error" *ngIf="isErrors()">
        <div class="flex flex-column gap-2">
          <ng-container>
            <ng-container *ngFor="let errorArr of errorKeys">
              <div class="flex flex-row" *ngFor="let error of errors[errorArr]">
                <i class="pi pi-exclamation-circle"></i>
                <p>
                  {{ error | translate }}
                </p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="flex gap-3 mt-1 mb-5">
      <p-button
        label="{{ 'booking.user.save_changes' | translate }}"
        type="submit"
        [loading]="loading"
      />
    </div>
  </form>
  <div class="p-2 flex-auto xl:ml-5"></div>
  <div class="text-900 font-semibold text-lg mt-3">
    {{ "profile.account_privacy" | translate }}
  </div>
  <p-divider class="p-element"></p-divider>
  <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
    <div class="flex gap-5 flex-column-reverse md:flex-row">
      <div class="flex flex-wrap">
        <!-- Name -->
        <div class="mb-2 md:col-4 sm:col-12">
          <label for="current_password">{{
            "auth.current_password" | translate
          }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-key"></i>
            </p-inputGroupAddon>
            <input
              type="password"
              pInputText
              placeholder="{{ 'auth.current_password' | translate }}"
              formControlName="current_password"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  passwordForm.get('current_password')?.invalid
              }"
              required
            />
          </p-inputGroup>
        </div>

        <!-- Phone number -->
        <div class="mb-2 md:col-4 sm:col-12">
          <label for="new_password">{{
            "auth.new_password" | translate
          }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-key"></i>
            </p-inputGroupAddon>
            <input
              type="password"
              pInputText
              placeholder="{{ 'auth.new_password' | translate }}"
              formControlName="new_password"
              [ngClass]="{
                'ng-invalid ng-dirty': passwordForm.get('new_password')?.invalid
              }"
              required
            />
          </p-inputGroup>
        </div>

        <!-- Email -->
        <div class="mb-2 md:col-4 sm:col-12">
          <label for="new_password_confirmation">{{
            "auth.new_password_confirmation" | translate
          }}</label>
          <p-inputGroup class="mt-2">
            <p-inputGroupAddon>
              <i class="pi pi-key"></i>
            </p-inputGroupAddon>
            <input
              type="password"
              pInputText
              placeholder="{{ 'auth.new_password_confirmation' | translate }}"
              formControlName="new_password_confirmation"
              [ngClass]="{
                'ng-invalid ng-dirty': passwordForm.get(
                  'new_password_confirmation'
                )?.invalid
              }"
              required
            />
          </p-inputGroup>
        </div>
      </div>
    </div>
    <div class="flex gap-3 mt-1 mb-3">
      <div class="message-container error" *ngIf="isPasswordErrors()">
        <div class="flex flex-column gap-2">
          <ng-container>
            <ng-container *ngFor="let errorArr of passwordErrorKeys">
              <div
                class="flex flex-row"
                *ngFor="let error of passwordErrors[errorArr]"
              >
                <i class="pi pi-exclamation-circle"></i>
                <p>
                  {{ error | translate }}
                </p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="flex gap-3 mt-1 mb-5">
      <p-button
        label="{{ 'booking.user.change_password' | translate }}"
        type="submit"
        [loading]="passwordLoading"
      />
    </div>
  </form>
</ng-container>

<p-toast [position]="'top-center'"></p-toast>
