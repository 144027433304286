import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../util/user.model';
import { ProfileService } from '../../data-access/profile.service';
import { UserStateService } from '../../data-access/user-state.service';

@Component({
  selector: 'app-user-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent,
    FooterComponent,
    TabMenuModule,
    NgIf,
    TranslateModule,
  ],
  template: `
    <div class="content">
      <div class="container">
        <div class="section flex flex-column">
          <div class="section-header">
            <div class="section-title">
              <h1>{{ 'booking.user.welcome' | translate }} {{ user?.name }}</h1>
            </div>
          </div>
          <hr class="mt-4 mb-5" />
          <div class="section-content mb-5">
            <p-tabMenu [model]="items" [activeItem]="activeItem" />
            <router-outlet></router-outlet>
          </div>
        </div>
        <div class="section">
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
  router = inject(Router);
  userState = inject(UserStateService);
  translateService = inject(TranslateService);
  profileService = inject(ProfileService);

  items: MenuItem[] | undefined;
  activeItem: MenuItem | undefined;
  user: User | undefined;

  ngOnInit() {
    this.items = [
      {
        label: this.translateService.instant('profile.cards'),
        icon: 'pi pi-credit-card',
        command: () => {
          this.router.navigate(['/dashboard/cards']);
        },
      },
      {
        label: this.translateService.instant('profile.personal_info'),
        icon: 'pi pi-user',
        command: () => {
          this.router.navigate(['/dashboard/profile']);
        },
      },
      {
        label: this.translateService.instant('profile.orders'),
        icon: 'pi pi-shopping-cart',
        command: () => {
          this.router.navigate(['/dashboard/orders']);
        },
      },
    ];

    // Set active item based on current route
    const currentPath = this.router.url.split('/').pop();
    this.activeItem = this.items.find(
      (item) => item.command && item.command.toString().includes(currentPath!)
    );

    this.getUserInfo();
  }

  getUserInfo() {
    this.userState.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }
}
