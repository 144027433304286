import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { EventForm } from '../util/event-form.model';
import { eventFormApis } from '../util/event-form.apis';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  customHttp = inject(CustomHttpClientService);

  sendEmail(eventForm: EventForm) {
    return this.customHttp
      .post<EventForm>(eventFormApis.sendEmail, eventForm.toJson())
      .pipe(first());
  }
}
