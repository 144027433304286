import { Injectable } from '@angular/core';

declare const gtag: any;

@Injectable()
export class GoogleEventTrackerService {
  constructor() {}

  public static trackEvent(key: string, properties: Object) {
    if (typeof gtag === 'undefined') {
      return;
    }
    gtag('event', key, properties);
  }
}
