import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../util/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  setUser(user: User | null) {
    this.userSubject.next(user);
  }
}
