<div class="carousel-container">
  <div class="carousel">
    <ng-container *ngIf="banners; else bannerLoading">
      <div class="carousel-images">
        <div
          *ngFor="let banner of banners; let i = index"
          class="carousel-image"
          [ngStyle]="{
            '--bg-image':
              'url(https://ik.imagekit.io/Malahi/tr:w-1563,h-750,c-at_least/' +
              banner.image +
              ')',
          }"
          [ngClass]="{
            'carousel-image-inactive': i !== activeBannerIndex
          }"
        >
          <!-- TODO: return alt from backend -->
          <app-ngx-ik-image-cdn [path]="banner.image"></app-ngx-ik-image-cdn>
        </div>
      </div>
      <div class="images-indicator">
        <div class="d-flex jcc">
          <div
            *ngFor="let banner of banners; let i = index"
            class="indicator"
            [ngClass]="{ active: i === activeBannerIndex }"
            (click)="goToBanner(i)"
          ></div>
        </div>
      </div>
    </ng-container>
    <ng-template #bannerLoading>
      <p-skeleton width="100%" height="70vh"></p-skeleton>
    </ng-template>
  </div>
</div>

<div class="content">
  <div class="container section">
    <div class="section-header mb-1 mt-5">
      <div class="section-description">
        <div class="section-title">
          <h1>{{ "home.branches.title" | apiTranslation }}</h1>
        </div>

        <div class="section-subtitle">
          <h2>{{ "home.branches.description" | apiTranslation }}</h2>
        </div>
      </div>
      <div class="section-link">
        <!-- <a href=""
          >{{ "branches.explore_branches.all_branches" | apiTranslation }}

          <i class="pi pi-arrow-up-left"></i>
        </a> -->
      </div>
    </div>
  </div>
  <div class="branch-slider">
    <div class="branch-slider-container">
      <div class="branch-slider-content">
        <div class="branch-slider-items" #branchSlider>
          <ng-container
            *ngIf="branches$ | async as branches; else branchesLoading"
          >
            <div
              class="branch-slider-item"
              *ngFor="let branch of branches"
              (click)="goToBranch(branch.siteId)"
            >
              <div class="branch-slider-item-image">
                <app-ngx-ik-image-cdn
                  width="30"
                  [path]="branch.icon"
                ></app-ngx-ik-image-cdn>
              </div>
              <div class="branch-slider-item-title">
                <h1>{{ branch.name }}</h1>
              </div>
            </div>
          </ng-container>
          <ng-template #branchesLoading>
            <p-skeleton
              [shape]="'circle'"
              width="40px"
              height="40px"
            ></p-skeleton>
            <div style="width: 1rem"></div>
            <p-skeleton width="100px" height="40px"></p-skeleton>
          </ng-template>
        </div>
        <!-- <div class="scroll-button scroll-button-right">
          <button (click)="scrollBranchSlider('right')">
            <i class="pi pi-chevron-{{ 'right' }}"></i>
          </button>
        </div>
        <div class="scroll-button scroll-button-left">
          <button (click)="scrollBranchSlider('left')">
            <i class="pi pi-chevron-{{ 'left' }}"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <div class="container">
    <div class="attractions section">
      <div class="section-header">
        <div class="section-description">
          <div class="section-title">
            <h1>{{ "home.explore.title" | apiTranslation }}</h1>
          </div>
          <div class="line-twirled bidi-image">
            <app-ngx-ik-image-cdn
              path="images/line-twirled.svg"
            ></app-ngx-ik-image-cdn>
          </div>
          <div class="section-subtitle">
            <h2>{{ "home.explore.description" | apiTranslation }}</h2>
          </div>
        </div>
        <div class="section-link">
          <!-- <a href=""
            >{{ "branches.explore_branches.all_branches" | apiTranslation }}

            <i class="pi pi-arrow-up-left"></i>
          </a> -->
        </div>
      </div>
      <div *ngIf="scrollOverflow" @fadeInOut class="scroll-buttons ltr">
        <div class="scroll-left">
          <button
            pButton
            type="button"
            (click)="scrollLeft()"
            icon="pi pi-chevron-left"
            class="p-button-rounded p-button-secondary"
          ></button>
        </div>
        <div class="scroll-right">
          <button
            pButton
            type="button"
            (click)="scrollRight()"
            icon="pi pi-chevron-right"
            class="p-button-rounded p-button-secondary"
          ></button>
        </div>
      </div>
      <div class="section-list tickets-list">
        <ng-container
          *ngIf="products$ | async as products; else productsLoading"
        >
          <app-product-card
            *ngFor="let product of products; let pIndex = index"
            [product]="product"
            [active]="hoveredProduct === pIndex"
            (mouseenter)="hoveredProduct = pIndex"
            (mouseleave)="hoveredProduct = -1"
            [minimal]="true"
          ></app-product-card>
          <!-- (click)="goToProduct(product.localProduct.product_id)" -->
        </ng-container>
        <ng-template #productsLoading>
          <p-skeleton
            *ngFor="let _ of [].constructor(4)"
            width="100%"
            height="300px"
          ></p-skeleton>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="events$ | async as events">
      <div class="enquiries section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <h1>{{ "home.events.title" | apiTranslation }}</h1>
            </div>
          </div>
        </div>
        <div class="d-flex jcb aic enquiries-container">
          <div class="section-list enquiries-list">
            <div
              *ngFor="let event of events; let i = index"
              class="category chip"
              (click)="setActiveEvent(i)"
              [ngClass]="{ selected: i === activeEventIndex }"
            >
              <h1>{{ event.name }}</h1>
            </div>
          </div>
          <div class="section-link">
            <!-- <a href=""
              >{{ "events.explore_events" | apiTranslation }}
              <i class="pi pi-arrow-up-left"></i>
            </a> -->
          </div>
        </div>
        <div class="enquery-items">
          <div class="section-header">
            <div class="section-description">
              <div class="section-subtitle">
                <h2>
                  {{ events[activeEventIndex].description }}
                </h2>
              </div>
            </div>
          </div>
          <div class="section-list events-list">
            <div
              *ngFor="let image of events[activeEventIndex].reservation_events"
              class="card-container"
            >
              <app-card
                [headerImage]="image.image"
                [routerLink]="'/events/' + image.id"
                [color]="'#ff0000'"
                [isEvent]="true"
              >
                <ng-template #cardContent>
                  <div class="card-content">
                    <div class="product-title">
                      {{ image.name }}
                    </div>
                    <div class="card-actions">
                      <div class="card-action">
                        <button
                          pButton
                          pRipple
                          [label]="
                            'branches.products.product.view_details'
                              | apiTranslation
                          "
                          icon="pi pi-arrow-up-left"
                          iconPos="right"
                        ></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </app-card>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="brands$ | async as brands">
      <div class="brands section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <h1>{{ "home.services.title" | apiTranslation }}</h1>
            </div>
            <div class="section-subtitle">
              {{ "home.services.description" | apiTranslation }}
            </div>
          </div>
        </div>
        <div class="section-list brands-list jcse aic">
          <div *ngFor="let brand of brands" class="brand">
            <a [href]="brand.url" target="_blank">
              <app-ngx-ik-image-cdn
                [path]="brand.image ?? ''"
              ></app-ngx-ik-image-cdn>
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="section footer-section">
      <app-footer></app-footer>
    </div>
  </div>
</div>
