import { Pipe, PipeTransform, inject } from '@angular/core';
import { LanguageService } from '../services/language-service/language.service';

@Pipe({
  name: 'apiTranslation',
  standalone: true,
})
export class ApiTranslationPipe implements PipeTransform {
  languageService = inject(LanguageService);

  transform(value: string, ...args: unknown[]): string {
    return this.languageService.translate(value);
  }
}
