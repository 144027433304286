<div class="card">
  <div class="card-images">
    <app-ngx-ik-image-cdn
      [path]="headerImage || 'default-image.jpg'"
      [ngClass]="{
        large: isEvent
      }"
      class="card-image"
    ></app-ngx-ik-image-cdn>
    <div
      *ngIf="headerIcon || headerTitle"
      [ngStyle]="{
        'background-color': convertColorWithOpacity(
          color ?? 'rgb(75, 0, 130)',
          0.6
        )
      }"
      class="card-description"
    >
      <div *ngIf="headerIcon" class="card-icon">
        <app-ngx-ik-image-cdn
          [path]="headerIcon"
          width="40"
        ></app-ngx-ik-image-cdn>
      </div>

      <div *ngIf="headerTitle" class="card-title">
        <h1>{{ headerTitle }}</h1>
      </div>
    </div>
  </div>
  <div *ngIf="cardContent" class="card-body">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </div>
  <div *ngIf="cardFooter" class="card-footer">
    <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
  </div>
</div>
