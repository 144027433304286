import * as uuid from 'uuid';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
// import { NgXCreditCardsModule } from 'ngx-credit-cards';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CreditCardDirectivesModule,
  CreditCardValidators,
} from 'angular-cc-library';
import { SkeletonModule } from 'primeng/skeleton';
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  first,
  of,
  tap,
} from 'rxjs';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { CartService } from '../../data-access/cart.service';
import { CheckoutService } from '../../data-access/checkout.service';
import { Transaction } from '../../util/transaction.model';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { FacebookPixelEventTrackerService } from '../../../core/services/facebook-pixel-event-tracker.service';
import { GoogleEventTrackerService } from '../../../core/services/google-event-tracker.service';
import { SnapEventTrackerService } from '../../../core/services/snap-event-tracker.service';
import { AllowNumbersAndLettersDirective } from '../../../directives/allow-numbers-and-letters.directive';
import {
  NgxPhoneNumberInputModule,
  PhoneInputComponent,
} from 'ngx-lib-phone-input';
import { CountryListItemType, countries } from 'country-list-json';
import { TiktokEventTrackerService } from '../../../core/services/tiktok-event-tracker.service';
import { ProfileService } from '../../../user/data-access/profile.service';
import { User } from '../../../user/util/user.model';
import { DividerModule } from 'primeng/divider';

declare var TamaraWidgetV2: any;
declare var TabbyPromo: any;

declare global {
  interface Window {
    tamaraWidgetConfig: any;
  }
}

// Extend the Window interface to include TamaraWidgetV2
declare global {
  interface Window {
    TamaraWidgetV2: any;
  }
}

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AsyncPipe,
    DecimalPipe,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    NgClass,
    TranslateModule,
    RouterModule,
    SkeletonModule,
    FooterComponent,
    HeaderComponent,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    AllowNumbersAndLettersDirective,
    NgxPhoneNumberInputModule,
    DividerModule,
  ],
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  router = inject(Router);
  route = inject(ActivatedRoute);
  profileService = inject(ProfileService);
  cartService = inject(CartService);
  checkoutService = inject(CheckoutService);
  _cd = inject(ChangeDetectorRef);
  elementRef = inject(ElementRef);
  languageService = inject(LanguageService);
  translateService = inject(TranslateService);

  @ViewChild(PhoneInputComponent) phoneInputComponent!: PhoneInputComponent;
  selectedPaymentMethod: string = '';
  cart: any[] = [];
  user!: User;
  userForm!: FormGroup;
  transaction$: Observable<Transaction[] | null> | undefined;
  appleTransaction$: Observable<any> | undefined;
  payment$: Observable<any> | undefined;
  transactions!: Transaction[];
  coupon!: string;
  session: any;
  cardNumbers: any = {};
  errors: string[] = [];
  applePayErrors: string[] = [];
  creditErrors: string[] = [];

  isLoading: boolean = false;
  isTabbyLoading: boolean = false;
  isTabbyAllowed: boolean = true;
  isTamaraLoading: boolean = false;
  isTamaraAllowed: boolean = true;
  isAllLoading: boolean = false;
  isCouponLoading: boolean = false;
  validCoupon!: boolean;
  userFormSubmitted: boolean = false;
  creditFormSubmitted: boolean = false;
  success: boolean = false;
  isApplePaySupported: boolean = false;
  isValidEmail: boolean = true;
  counter: number = 5;
  intervalId: any;
  redirectingUrl!: string;
  allCountries: CountryListItemType[] = [];
  tiktokContent: any[] = [];

  private queryParamsSubscription: Subscription | null = null;

  constructor(private _fb: FormBuilder) {
    this.allCountries = countries
      .filter((country) => country.code !== 'IL')
      .map((country) => {
        if (country.code === 'PS') {
          return { ...country, name: 'Palestine' };
        }
        return country;
      });
  }

  ngAfterViewInit(): void {
    // Access ViewChild reference here
    this.phoneInputComponent.countriesWithCodes = this.allCountries;
    this.phoneInputComponent.countrySelect(
      this.allCountries.find((country) => country.code === 'SA')!
    );

    this.phoneInputComponent.registerOnChange((event: any) => {
      this.registerOnChange(event);
    });

    this.getUserInfo();
  }

  registerOnChange(number: any) {
    const phoneNumberControl = this.userForm.get('phoneNumber');
    if (phoneNumberControl?.value != number) {
      phoneNumberControl?.setValue(number);
      return;
    }
  }

  ngOnInit(): void {
    this.cart = this.cartService.getItems();

    if (!this.cart.length) {
      this.router.navigate(['/']);
    }

    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        if (params['cards']) {
          const decodedObj = decodeURIComponent(params['cards']);
          const myObject = JSON.parse(decodedObj);
          this.cardNumbers = myObject; // This will be the original object
        }
        if (params['payment_id']) {
          this.isTabbyLoading = true;
          this._cd.markForCheck();
          this.checkoutService
            .getTabbyTransaction(params['payment_id'])
            .subscribe((res) => {
              if (res['status'] == 'REJECTED') {
                this.errors = [
                  this.translateService.instant('errors.tabby_error'),
                ];
                this.isTabbyAllowed = false;
                this._cd.markForCheck();
              }
            })
            .add(() => {
              this.isTabbyLoading = false;
              this._cd.markForCheck();
            });
        }
      }
    );

    this.selectedPaymentMethod = 'visaCard';

    const items: any[] = [];

    this.cart.forEach((product) => {
      items.push({
        ProductId: product.ProductId,
        Quantity: product.quantity,
        SiteId: product.SiteId,
        CardNumber: this.cardNumbers[product.SiteId] ?? null,
      });
    });

    this.isLoading = true;
    this._cd.markForCheck();
    this.transaction$ = this.checkoutService.beginTransaction(items).pipe(
      tap((value) => {
        this.transactions = value;
        GoogleEventTrackerService.trackEvent('begin_checkout', {
          items: this.cart,
        });
        FacebookPixelEventTrackerService.trackEvent('begin_checkout', {
          items: this.cart,
        });
        SnapEventTrackerService.trackEvent('START_CHECKOUT', {
          price: this.totalTransactionAmount('TransactionNetAmount'),
          currency: 'SAR',
          item_ids: this.cart.map((obj) => `${obj.ProductId}`),
          item_category: 'Malahi',
          number_items: this.cart.reduce((sum, obj) => sum + obj.quantity, 0),
          payment_info_available: 0,
        });

        this.transactions.forEach((transaction) => {
          transaction.TransactionLinesDTOList.forEach((line) => {
            let existingItem = this.tiktokContent.find(
              (item) => item.content_id === `${line.ProductId}`
            );
            if (existingItem) {
              existingItem.quantity += 1;
            } else {
              this.tiktokContent.push({
                content_id: `${line.ProductId}`,
                content_type: 'product',
                content_category: `${transaction.SiteId}`,
                content_name: `${line.ProductName}`,
                quantity: 1,
                price: `${line.Amount}`,
              });
            }
          });
        });

        TiktokEventTrackerService.trackEvent('InitiateCheckout', {
          contents: this.tiktokContent,
          value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
          currency: 'SAR',
        });
        this.loadTabby();
      }),
      catchError((error) => {
        this.router.navigate([`/cart`], {
          queryParams: { error: error.error.index },
        });
        return of(null);
      }),
      finalize(() => {
        this.isLoading = false;
        this._cd.markForCheck();
      })
    );

    this.userForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^[- +()0-9]+$'),
          Validators.minLength(10),
        ],
      ],
      termsCheck: [false, Validators.requiredTrue],
    });
  }

  getUserInfo() {
    if (!localStorage.getItem(environment.jwtKey)) {
      if (!localStorage.getItem('userInfo')) return;
      const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '');

      this.userForm.get('email')?.setValue(userInfo.email);
      this.userForm.get('firstName')?.setValue(userInfo.firstName);
      this.userForm.get('lastName')?.setValue(userInfo.lastName);

      setTimeout(() => {
        this.userForm.get('phoneNumber')?.setValue(userInfo.phoneNumber);
      }, 100);

      return;
    }
    this.profileService.getUserInfo().subscribe((user) => {
      this.user = user;

      this.userForm.get('email')?.setValue(user.email);
      this.userForm.get('firstName')?.setValue(user.name.split(' ')[0]);
      try {
        this.userForm.get('lastName')?.setValue(user.name.split(' ')[1]);
      } catch (e) {}
      this.userForm.get('phoneNumber')?.setValue(user.phone_number);
    });
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }

    const existingScript = this.elementRef.nativeElement.querySelector(
      'script#tabby-script'
    );
    if (existingScript) {
      existingScript.remove();
    }
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      this.counter--;
      this._cd.markForCheck();
      if (this.counter <= 0) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  loadTabby() {
    if (typeof TabbyPromo === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://checkout.tabby.ai/tabby-promo.js';
      script.id = 'tabby-script';
      script.onload = () => {
        this.initTabby();
      };
      this.elementRef.nativeElement.appendChild(script);
    } else {
      this.initTabby();
    }
  }

  initTabby() {
    const tabbyCard = new TabbyPromo({
      selector: '#tabbyCard', // empty div for TabbyPromo.
      publicKey: environment.tabbyPublicKey,
      installmentsCount: 4,
      merchantCode: environment.merchantCode,
      currency: 'SAR', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      lang: this.languageService.getLanguage(), // Optional, language of snippet and popups.
      price: this.totalTransactionAmount('TransactionNetAmount'), // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      size: 'wide', // required, can be also 'wide', depending on the width.
      theme: 'green', // required, can be also 'default'.
      header: true, // if a Payment method name present already.
    });

    window.tamaraWidgetConfig = {
      lang: this.languageService.getLanguage(),
      country: 'SA',
      publicKey: environment.tamaraPublicKey,
      css: '.tamara-summary-widget--inline-outlined:hover {background-color: #272727 !important;}',
    };

    window.TamaraWidgetV2.refresh();
  }

  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
  }

  totalTransactionAmount(field: string) {
    return this.transactions.reduce(
      (sum, current) => sum + current[field as keyof Transaction],
      0
    );
  }

  flattenTransactionLines(): any[] {
    const list = this.transactions.flatMap(
      (transaction) => transaction.TransactionLinesDTOList
    );

    const result: any[] = [];
    const itemMap: any = {}; // To keep track of indices in the result array

    list.forEach((item) => {
      const key = `${item.ProductId}`;

      if (itemMap.hasOwnProperty(key)) {
        // If yes, increment quantity
        result[itemMap[key]].quantity += 1;
        result[itemMap[key]].Price += item.Price;
      } else {
        // If no, add to result and store its index in itemMap
        itemMap[key] = result.length;
        result.push({ ...item, quantity: 1 });
      }
    });

    return result;
  }

  totalTax() {
    return this.cartService.totalTax();
  }

  totalPrice() {
    return this.cartService.totalPrice();
  }

  applyCoupon() {
    this.isCouponLoading = true;
    this._cd.markForCheck();
    this.transaction$ = this.checkoutService
      .applyCoupon(this.transactions, this.coupon)
      .pipe(
        tap((value) => {
          const userInfo = JSON.parse(
            localStorage.getItem('userInfo') ??
              JSON.stringify({
                email: 'null',
                firstName: 'null',
                lastName: 'null',
                phoneNumber: 'null',
              })
          );
          this.transactions = value;
          this.validCoupon = true;
          GoogleEventTrackerService.trackEvent('apply_coupon', {
            coupon: this.coupon,
          });
          FacebookPixelEventTrackerService.trackEvent('apply_coupon', {
            coupon: this.coupon,
          });
          SnapEventTrackerService.trackEvent('CUSTOM_EVENT_1', {
            uuid_c1: uuid.v4(),
            event_tag: 'apply_coupon',
            user_email: userInfo.email,
            user_phone_number: userInfo.phoneNumber,
            firstname: userInfo.firstName,
            lastname: userInfo.lastName,
          });
        }),
        catchError((error) => {
          this.validCoupon = false;
          return of(null);
        }),
        finalize(() => {
          this.isCouponLoading = false;
          this._cd.markForCheck();
        })
      );

    // this.transaction$.subscribe();
  }

  async tabbyCheckout() {
    this.userFormSubmitted = true;
    this.creditFormSubmitted = true;
    if (!this.isValidUserForm()) {
      return;
    }
    this.isAllLoading = true;
    const isValidEmail = await this.validateEmail(
      this.userForm.get('email')?.value
    );

    if (!isValidEmail && environment.production) {
      return;
      this._cd.markForCheck();
    }

    TiktokEventTrackerService.identify(
      this.userForm.get('email')?.value,
      this.userForm.get('phoneNumber')?.value
    );
    TiktokEventTrackerService.trackEvent('PlaceAnOrder', {
      contents: this.tiktokContent,
      value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
      currency: 'SAR',
      description: 'tabby',
    });
    this.payment$ = this.checkoutService
      .checkoutTabbyTransaction(this.transactions, { ...this.userForm.value })
      .pipe(
        tap(
          (value) => {
            if (value.payment.warnings && value.payment.warnings.length > 0) {
              this.errors = [
                this.translateService.instant('errors.tabby_error'),
              ];
              this.isAllLoading = false;
              this._cd.markForCheck();
            } else {
              let url = '';
              try {
                url =
                  value.payment.configuration.available_products.installments[0]
                    .web_url;
              } catch (err) {
                this.errors = ['Unexpected error!'];
                this.isAllLoading = false;
                this._cd.markForCheck();
                return;
              }
              this.redirectingUrl = url;
              this.storeUserInfo();
              this.startCountdown();
              this._cd.markForCheck();
              window.location.href = url;
            }
          },
          (err) => {
            this.isAllLoading = false;
            this._cd.markForCheck();
          }
        )
      );

    this.payment$.subscribe();
  }

  async tamaraCheckout() {
    this.userFormSubmitted = true;
    this.creditFormSubmitted = true;
    if (!this.isValidUserForm()) {
      return;
    }
    this.isAllLoading = true;
    const isValidEmail = await this.validateEmail(
      this.userForm.get('email')?.value
    );

    if (!isValidEmail && environment.production) {
      return;
      this._cd.markForCheck();
    }

    TiktokEventTrackerService.identify(
      this.userForm.get('email')?.value,
      this.userForm.get('phoneNumber')?.value
    );
    TiktokEventTrackerService.trackEvent('PlaceAnOrder', {
      contents: this.tiktokContent,
      value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
      currency: 'SAR',
      description: 'tamara',
    });
    // Clean up phone number before sending userForm value
    const cleanedUserFormValue = {
      ...this.userForm.value,
      phoneNumber: this.userForm.value.phoneNumber.replace(/[-()\s]/g, ''),
    };

    this.payment$ = this.checkoutService
      .checkoutTamaraTransaction(this.transactions, cleanedUserFormValue)
      .pipe(
        tap(
          (value) => {
            if (value.payment.warnings && value.payment.warnings.length > 0) {
              this.errors = [
                this.translateService.instant('errors.tabby_error'),
              ];
              this.isAllLoading = false;
              this._cd.markForCheck();
            } else {
              let url = '';
              try {
                url = value.payment.checkout_url;
              } catch (err) {
                this.errors = ['Unexpected error!'];
                this.isAllLoading = false;
                this._cd.markForCheck();
                return;
              }
              this.redirectingUrl = url;
              this.storeUserInfo();
              this.startCountdown();
              this._cd.markForCheck();
              window.location.href = url;
            }
          },
          (err) => {
            this.isAllLoading = false;
            this._cd.markForCheck();
          }
        )
      );

    this.payment$.subscribe();
  }

  async checkout() {
    this.userFormSubmitted = true;
    this.creditFormSubmitted = true;
    if (!this.userForm.valid) {
      return;
    }
    this.isAllLoading = true;
    this._cd.markForCheck();
    const isValidEmail = await this.validateEmail(
      this.userForm.get('email')?.value
    );
    if (!isValidEmail && environment.production) {
      this.isAllLoading = false;
      this._cd.markForCheck();
      return;
    }
    TiktokEventTrackerService.identify(
      this.userForm.get('email')?.value,
      this.userForm.get('phoneNumber')?.value
    );
    TiktokEventTrackerService.trackEvent('PlaceAnOrder', {
      contents: this.tiktokContent,
      value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
      currency: 'SAR',
      description: 'card',
    });
    this.payment$ = this.checkoutService
      .checkoutTransaction(this.transactions, { ...this.userForm.value })
      .pipe(
        tap(
          (value) => {
            this.storeUserInfo();
            this.redirectingUrl = value['transaction_url'];
            this.startCountdown();
            this._cd.markForCheck();
            window.location.href = value['transaction_url'];
          },
          (err) => {
            this.isAllLoading = false;
            this.creditErrors = [
              err.error.message ??
                this.translateService.instant(
                  `errors.applepay.UNSPECIFIED_FAILURE`
                ),
            ];
            this._cd.markForCheck();
          }
        )
      );

    this.payment$.subscribe();
  }

  isValidUserForm() {
    return (
      this.userForm.get('email')?.valid &&
      this.userForm.get('firstName')?.valid &&
      this.userForm.get('lastName')?.valid &&
      this.isValidInput('phoneNumber') &&
      this.userForm.get('termsCheck')?.valid
    );
  }

  isValidInput(name: string, isUserForm = false) {
    if (!this.userForm) return false;
    const input = this.userForm.get(name);
    if (name == 'phoneNumber') {
      if (!this.phoneInputComponent) {
        return true;
      }
      const value = input?.value.replace(' ', '');
      if (value != input?.value) {
        input?.setValue(value);
      }
      const dialCode = this.phoneInputComponent.selectedCountry.dial_code;
      return (
        !this.userFormSubmitted ||
        // value.startsWith(dialCode) &&
        (value.length - dialCode.length >= 9 &&
          value.length - dialCode.length <= 10 &&
          input?.valid)
      );
    }
    return (
      (isUserForm ? this.userFormSubmitted : this.creditFormSubmitted) &&
      (input?.invalid || (name == 'email' && !this.isValidEmail))
    );
  }

  async validateEmail(
    email: string,
    isSession: boolean = false
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkoutService.validateEmail(email).subscribe(
        (res) => {
          this.isValidEmail = true;
          // if (isSession && (<any>window).ApplePaySession) {
          //   this.session.begin();
          // }
          resolve(true);
        },
        (err) => {
          this.isValidEmail = false;
          resolve(false);
        }
      );
    });
  }

  storeUserInfo() {
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        email: this.userForm.get('email')?.value ?? '',
        firstName: this.userForm.get('firstName')?.value ?? '',
        lastName: this.userForm.get('lastName')?.value ?? '',
        phoneNumber: this.userForm.get('phoneNumber')?.value ?? '',
      })
    );
  }
}
