export const checkoutApis = {
  beginTransaction: `/beginTransaction`,
  applyCoupon: `/applyCoupon`,
  getTransaction: `/getTransaction`,
  getTransactionByPayment: (paymentId: string) =>
    `/getTransactionByPayment/${paymentId}`,
  checkoutTransaction: `/checkoutTransaction`,
  checkoutTabbyTransaction: `/checkoutTabbyTransaction`,
  checkoutTamaraTransaction: `/checkoutTamaraTransaction`,
  getTabbyTransaction: `/getTabbyTransaction`,
  completeTransaction: `/completeTransaction`,
  completeTabbyTransaction: `/completeTabbyTransaction`,
  completeTamaraTransaction: `/completeTamaraTransaction`,
  completeAppleTransaction: `/completeAppleTransaction`,
  checkCardValidity: (branch: string, cardNumber: string) =>
    `/checkCardValidity?branch=${branch}&cardNumber=${cardNumber}`,
  validateEmail: (email: string) => `/validateEmail?email=${email}`,
  pingTicket: (paymentId: string, otp: string) =>
    `/pingTicket/${paymentId}/${otp}`,
  downloadTicket: (fileName: string) => `/downloadTicket?fileName=${fileName}`,
};
