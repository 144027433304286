<div class="content">
  <div class="content-inner">
    <div class="container">
      <div class="attractions section">
        <div class="section-header">
          <div class="section-description">
            <div class="section-title">
              <h1>
                {{ "booking.checkout.my_purchases" | apiTranslation }} ({{
                  cart.length
                }})
              </h1>
            </div>
            <div class="section-subtitle">
              <!-- <h2>
                Experience the world's Fastest Vertical Launch Rollercoaster in
                Dubai for the thrill of your life!
              </h2> -->
            </div>
          </div>
        </div>
        <form *ngIf="!success" [formGroup]="userForm" (ngSubmit)="checkout()">
          <div class="loading-wrapper" *ngIf="isAllLoading && !redirectingUrl">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <div class="loading-wrapper" *ngIf="redirectingUrl">
            <div class="loading-redirect">
              <i class="pi pi-spin pi-spinner"></i>
              <p>جاري تحويلك لصفحة التأكيد...</p>
              <p *ngIf="counter > 0">سيتم تحويلك خلال {{ counter }} ثواني.</p>
              <p *ngIf="counter <= 0">
                اذا لم يتم تحويلك لصفحة التأكيد،
                <a [href]="redirectingUrl">إضغظ هنا</a>.
              </p>
            </div>
          </div>
          <div class="checkout-container">
            <div class="checkout-details">
              <div class="checkout-user-info">
                <h2>
                  {{ "booking.checkout.details" | apiTranslation }}
                </h2>
                <div class="form-group">
                  <div class="form-control">
                    <input
                      id="firstName"
                      formControlName="firstName"
                      autocomplete="first-name"
                      type="text"
                      required
                      [placeholder]="'general.form.first_name' | translate"
                      [ngClass]="{
                        invalid: isValidInput('firstName', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="firstName"
                      *ngIf="isValidInput('firstName', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.required_field" | translate }}
                    </label>
                  </div>
                  <div class="form-control">
                    <input
                      id="lastName"
                      formControlName="lastName"
                      autocomplete="last-name"
                      type="text"
                      required
                      [placeholder]="'general.form.last_name' | translate"
                      [ngClass]="{
                        invalid: isValidInput('lastName', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="lastName"
                      *ngIf="isValidInput('lastName', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.required_field" | translate }}
                    </label>
                  </div>
                  <div class="form-control">
                    <input
                      id="email"
                      formControlName="email"
                      autocomplete="email"
                      type="email"
                      required
                      [placeholder]="'general.form.email' | translate"
                      [ngClass]="{
                        invalid: isValidInput('email', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="email"
                      *ngIf="isValidInput('email', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{
                        (isValidEmail
                          ? "form_validation.errors.email"
                          : "form_validation.errors.email_validity"
                        ) | translate
                      }}
                    </label>
                  </div>
                  <div class="form-control">
                    <ngx-lib-phone-input
                      dir="ltr"
                      formControlName="phoneNumber"
                    ></ngx-lib-phone-input>
                    <!-- <input
                      id="phoneNumber"
                      formControlName="phoneNumber"
                      autocomplete="tel"
                      maxlength="10"
                      type="tel"
                      required
                      [placeholder]="'general.form.phone' | translate"
                      [ngClass]="{
                        invalid: isValidInput('phoneNumber', true)
                      }"
                    /> -->
                    <label
                      class="error-label"
                      for="phoneNumber"
                      *ngIf="!isValidInput('phoneNumber', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.phone" | translate }}
                    </label>
                  </div>
                </div>
                <div class="flex flex-column gap-2">
                  <div class="terms-check">
                    <input
                      id="termsCheck"
                      formControlName="termsCheck"
                      name="termsCheck"
                      type="checkbox"
                    />
                    <label for="termsCheck">
                      {{
                        "booking.checkout.details.payment_details.terms_check_1"
                          | translate
                      }}
                      <a href="content/termsAndConditions">
                        {{
                          "booking.checkout.details.payment_details.terms_check_2"
                            | translate
                        }}
                      </a>
                    </label>
                  </div>
                  <label
                    class="error-label"
                    for="termsCheck"
                    *ngIf="isValidInput('termsCheck', true)"
                  >
                    <i class="pi pi-exclamation-circle"></i>
                    {{ "form_validation.errors.termsCheck_field" | translate }}
                  </label>
                </div>
              </div>
              <div class="checkout-payment-info">
                <h2>
                  {{ "booking.checkout.payment_details" | apiTranslation }}
                </h2>
                <div class="payment-methods">
                  <label
                    [ngClass]="{ active: selectedPaymentMethod == 'visaCard' }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-1"
                      (click)="selectPaymentMethod('visaCard')"
                    />
                    <img
                      height="15"
                      src="assets/images/Apple_Pay.png"
                      alt="mada-card-payment-method"
                    />
                    <img
                      height="15"
                      src="assets/images/master-card.png"
                      alt="master-card-payment-method"
                    />
                    {{ "booking.cart.checkout.card" | apiTranslation }}
                  </label>
                  <p-divider align="center" type="dotted" class="w-full">
                    <b>{{ "events.or" | translate }}</b>
                  </p-divider>
                  <p class="text-center mb-4 w-full">
                    {{ "booking.cart.checkout.tabby" | apiTranslation }}
                  </p>
                  <label
                    *ngIf="!isLoading && !isTabbyLoading"
                    class="d-flex aic tabby-radio mx-2"
                    [ngClass]="{
                      'disabled-contrast': !isTabbyAllowed,
                      active: selectedPaymentMethod == 'tabby'
                    }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-6"
                      [disabled]="!isTabbyAllowed"
                      (click)="selectPaymentMethod('tabby')"
                    />
                    <app-ngx-ik-image-cdn
                      path="images/tabby-new.webp"
                    ></app-ngx-ik-image-cdn>
                    <!-- {{ "booking.checkout.tabby" | translate }} -->
                  </label>
                  <label
                    *ngIf="!isLoading"
                    class="d-flex aic tabby-radio mx-2"
                    [ngClass]="{
                      'disabled-contrast': !isTabbyAllowed,
                      active: selectedPaymentMethod == 'tamara'
                    }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-6"
                      (click)="selectPaymentMethod('tamara')"
                    />
                    <img height="17" src="assets/images/tamara.png" alt="" />
                    <!-- {{ "booking.checkout.tamara" | translate }} -->
                  </label>
                  <div *ngIf="isLoading || isTabbyLoading" style="width: 10rem">
                    <p-skeleton height="2.5rem" borderRadius="5px"></p-skeleton>
                  </div>
                  <!-- <div *ngIf="isLoading || isTabbyLoading" style="width: 10rem">
                    <p-skeleton height="2.5rem" borderRadius="5px"></p-skeleton>
                  </div> -->
                </div>
                <div class="visa-separator"><hr /></div>
                <ng-container *ngIf="selectedPaymentMethod == 'visaCard'">
                  <a
                    class="powered"
                    href="https://moyasar.com/?ref=payment-form-v1.10.0"
                    target="_blank"
                    >Powered by <strong>Moyasar</strong></a
                  >
                  <div class="checkout-btn" *ngIf="!isLoading">
                    <button type="submit" [disabled]="isLoading">
                      <ng-container *ngIf="!isLoading; else loading">
                        {{ "booking.cart.checkout" | apiTranslation }}
                        {{
                          totalTransactionAmount("TransactionNetAmount")
                            | number : "1.2-2"
                        }}
                        SAR
                      </ng-container>
                      <ng-template #loading>
                        <i class="pi pi-spin pi-spinner"></i>
                      </ng-template>
                    </button>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    selectedPaymentMethod == 'visaCard' &&
                    creditErrors.length > 0
                  "
                >
                  <br />
                  <p class="error" *ngFor="let error of creditErrors">
                    * {{ error }}
                  </p>
                  <br />
                </ng-container>
                <div
                  class="mt-3"
                  id="tabbyCard"
                  [hidden]="selectedPaymentMethod != 'tabby'"
                ></div>
                <div
                  class="mt-3 mw-72"
                  *ngIf="selectedPaymentMethod == 'tamara'"
                >
                  <tamara-widget
                    type="tamara-summary"
                    amount="{{
                      totalTransactionAmount('TransactionAmount')
                        | number : '1.2-2'
                    }}"
                    inline-type="6"
                    inline-variant="outlined"
                    config='{"badgePosition":"","showExtraContent":"full","hidePayInX":false}'
                  ></tamara-widget>
                </div>
                <ng-container *ngIf="errors.length > 0">
                  <br />
                  <p class="error" *ngFor="let error of errors">
                    * {{ error }}
                  </p>
                  <br />
                </ng-container>
                <div
                  class="checkout-btn"
                  *ngIf="
                    (!isLoading && selectedPaymentMethod == 'tabby') ||
                    selectedPaymentMethod == 'tamara'
                  "
                >
                  <button
                    type="button"
                    class="{{ selectedPaymentMethod }}"
                    (click)="
                      selectedPaymentMethod == 'tabby'
                        ? tabbyCheckout()
                        : tamaraCheckout()
                    "
                    [disabled]="isLoading"
                  >
                    <ng-container *ngIf="!isLoading; else loading">
                      {{ "booking.cart.checkout" | apiTranslation }}
                      {{
                        totalTransactionAmount("TransactionNetAmount") / 4
                          | number : "1.2-2"
                      }}
                      SAR
                    </ng-container>
                    <ng-template #loading>
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
            <div class="bill-info-container">
              <div class="bill-info">
                <div class="promo">
                  <input
                    type="text"
                    [placeholder]="
                      'booking.checkout.promo_code' | apiTranslation
                    "
                    [(ngModel)]="coupon"
                    appAllowNumbersAndLetters
                    [ngModelOptions]="{ standalone: true }"
                    [ngClass]="{
                      valid: validCoupon == true,
                      invalid: validCoupon == false,
                    }"
                  />

                  <button
                    type="button"
                    (click)="applyCoupon()"
                    [disabled]="isCouponLoading"
                  >
                    <ng-container *ngIf="!isCouponLoading; else loading">
                      {{ "booking.checkout.promo_button" | apiTranslation }}
                    </ng-container>
                    <ng-template #loading>
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-template>
                  </button>
                </div>

                <label class="error-label" *ngIf="validCoupon == false">
                  <i class="pi pi-exclamation-circle"></i>
                  {{ "form_validation.errors.invalid_coupon" | translate }}
                </label>

                <h2 class="mt-2">
                  {{ "booking.cart.amount" | apiTranslation }}
                </h2>

                <ng-container *ngIf="transaction$ | async as ts">
                </ng-container>
                <ng-container *ngIf="!isLoading; else loadingTransaction">
                  <ng-container *ngIf="transactions; else errorTransaction">
                    <div class="bill">
                      <div
                        class="bill-item"
                        *ngFor="let product of flattenTransactionLines()"
                      >
                        <div class="product-name">
                          <p class="desc">{{ product["ProductName"] }}</p>
                          <span
                            class="item-count-span"
                            *ngIf="product['quantity'] > 1"
                          >
                            <span>x </span>
                            <span>{{ product["quantity"] }}</span>
                          </span>
                        </div>

                        <p class="price">
                          {{ product["Price"] | number : "1.2-2" }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-item">
                        <p class="desc">
                          <!-- {{
                            transactions[0].TransactionLinesDTOList[0][
                              "taxName"
                            ]
                          }} -->
                          15% {{ "booking.cart.vat" | apiTranslation }}
                        </p>
                        <p class="price">
                          {{
                            totalTransactionAmount("TaxAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="divider"></div>
                      <div class="bill-item">
                        <p class="desc">
                          {{ "booking.cart.subtotal_price" | apiTranslation }}
                        </p>
                        <p class="price">
                          {{
                            totalTransactionAmount("TransactionAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-item">
                        <p class="desc">
                          {{ "booking.cart.discount" | apiTranslation }}
                        </p>
                        <p class="price">
                          -{{
                            totalTransactionAmount("TransactionDiscountAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="divider"></div>
                      <div class="bill-item">
                        <h3>
                          {{ "booking.cart.total_price" | apiTranslation }}
                        </h3>
                        <h3 class="total-price">
                          {{
                            totalTransactionAmount("TransactionNetAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </h3>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #errorTransaction>
                    <p>Unexpected error. Try again later.</p>
                  </ng-template>
                </ng-container>
                <ng-template #loadingTransaction>
                  <ng-container *ngFor="let _ of [].constructor(5)">
                    <div class="ticket-skeleton mt-1">
                      <p-skeleton
                        height="2rem"
                        borderRadius="10px"
                      ></p-skeleton>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </form>
        <!-- </ng-container> -->
      </div>
      <div class="section">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
