import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SkeletonModule } from 'primeng/skeleton';
import { FooterService } from './footer.service';
import { ApiTranslationPipe } from '../../pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { FooterItem } from './footer-item.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgStyle,
    NgFor,
    SkeletonModule,
    RouterLink,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    TranslateModule,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  public service = inject(FooterService);

  isLinkItem(item: FooterItem) {
    const allowedItems = [
      'twitter',
      'x',
      'facebook',
      'mobile',
      'instagram',
      'email',
      'phone',
    ];
    return allowedItems.includes(item.title?.toLowerCase() ?? '');
  }

  getItemLink(item: FooterItem) {
    switch (item.title?.toLowerCase()) {
      case 'email':
        return `mailto:${item.content}`;
      case 'mobile':
      case 'phone':
        return `tel:${item.content}`;
      default:
        return item.content;
    }
  }

  getItemIcon(item: FooterItem) {
    switch (item.title?.toLowerCase()) {
      case 'x':
      case 'twitter':
        return 'pi pi-twitter';
      case 'facebook':
        return 'pi pi-facebook';
      case 'instagram':
        return 'pi pi-instagram';
      case 'email':
        return 'pi pi-envelope';
      case 'mobile':
      case 'phone':
        return 'pi pi-phone';
      default:
        return '';
    }
  }

  getItemStr(item: FooterItem) {
    switch (item.title?.toLowerCase()) {
      case 'facebook':
        return (
          item.content
            ?.replace('https://www.facebook.com/', '')
            .replace('people/', '')
            .split('/')[0] ?? item.content
        );
      case 'x':
      case 'twitter':
      case 'instagram':
        return (
          item.content?.replace('https://', '').split('/')[1] ?? item.content
        );
      default:
        return item.content;
    }
  }
}
