<div class="content">
  <div class="container">
    <div class="page-content">
      <ng-container *ngIf="product$ | async as product; else productLoading">
        <div class="product-info">
          <div class="branch-icon">
            <app-ngx-ik-image-cdn
              [path]="product.branch.icon"
            ></app-ngx-ik-image-cdn>
          </div>
          <div class="product-info-text">
            <div class="branch-title">
              {{ product.branch.name }}
            </div>
            <div class="product-title">
              {{ product.localProduct.name }}
            </div>
          </div>
        </div>
        <div class="gallery grid">
          <div
            *ngFor="let image of product.localProduct.images; let i = index"
            class="grid-item"
          >
            <app-ngx-ik-image-cdn
              (click)="activeImage = i; displayBasic = true"
              [path]="image"
            ></app-ngx-ik-image-cdn>
          </div>

          <p-galleria
            [value]="product.localProduct.images"
            [(visible)]="displayBasic"
            [responsiveOptions]="responsiveOptions"
            [containerStyle]="{ 'max-width': '850px' }"
            [activeIndex]="activeImage"
            [numVisible]="product.localProduct.images.length"
            [circular]="true"
            [fullScreen]="true"
            [showItemNavigators]="true"
            [showThumbnails]="true"
          >
            <ng-template pTemplate="item" let-item>
              <img
                [src]="baseStorage + '/' + item"
                style="width: 100%; display: block"
              />
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
              <div class="grid grid-nogutter justify-content-center">
                <img
                  [src]="baseStorage + '/' + item"
                  style="display: block"
                  height="50"
                />
              </div>
            </ng-template>
          </p-galleria>
        </div>

        <div class="actions">
          <button
            pButton
            pRipple
            [label]="'products.add_to_cart' | apiTranslation"
            class="p-button-primary"
            icon="pi pi-shopping-cart"
            iconPos="right"
            (click)="addToCart(product)"
          ></button>
        </div>

        <div
          class="flex flex-wrap flex-column justify-content-center align-items-center gap-3 mt-4"
        >
          <div id="tabbyCard"></div>
          <div id="tamaraWidgetContainer"></div>
        </div>

        <div class="info-section description-section">
          <div class="info-section-title">
            {{ "branches.products.product.details.description" | translate }}
          </div>
          <div
            class="info-section-description"
            [innerHTML]="product.localProduct.full_description"
          ></div>
        </div>

        <div class="divider-dashed"></div>

        <div class="info-section included-section">
          <div class="info-section-title">
            {{ "branches.products.product.details.whats_included" | translate }}
          </div>
          <div
            class="info-section-description"
            [innerHTML]="product.localProduct.whats_included"
          ></div>
        </div>

        <div class="info-section terms-section">
          <div class="info-section-title">
            {{ "branches.products.product.details.terms" | translate }}
          </div>
          <div
            class="info-section-description"
            [innerHTML]="product.localProduct.terms"
          ></div>
        </div>
      </ng-container>
      <ng-template #productLoading>
        <div class="product-info">
          <div class="branch-icon">
            <p-skeleton width="100px" height="100px"></p-skeleton>
          </div>
          <div class="product-info-text">
            <div class="branch-title">
              <p-skeleton width="100px" height="20px"></p-skeleton>
            </div>
            <div class="product-title">
              <p-skeleton width="100px" height="20px"></p-skeleton>
            </div>
          </div>
        </div>
        <div class="gallery grid">
          <div
            *ngFor="let _ of [].constructor(4); let i = index"
            class="grid-item"
          >
            <p-skeleton
              width="100%"
              [height]="i == 0 ? '23rem' : '11rem'"
            ></p-skeleton>
          </div>
        </div>

        <div class="info-section description-section">
          <div class="info-section-title">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
          <div class="info-section-description">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
        </div>

        <div class="divider-dashed"></div>

        <div class="info-section included-section">
          <div class="info-section-title">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
          <div class="info-section-description">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
        </div>

        <div class="info-section terms-section">
          <div class="info-section-title">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
          <div class="info-section-description">
            <p-skeleton width="100%" height="20px"></p-skeleton>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="section">
      <app-footer></app-footer>
    </div>
  </div>
</div>
<p-toast position="top-center" key="confirm" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <strong
          ><span class="font-bold text-900">{{
            "general.toast.success" | apiTranslation
          }}</span></strong
        >
      </div>
      <div class="font-medium text-lg my-3 text-900">{{ message.summary }}</div>
      <p-button
        class="p-button-sm toast-btn"
        label="{{ 'products.toast.navigate_cart' | apiTranslation }}"
        (click)="onConfirm()"
      ></p-button>
    </div>
  </ng-template>
</p-toast>
