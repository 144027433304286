<div class="content">
  <div class="container">
    <div class="section flex flex-column align-items-center">
      <div class="section-header">
        <div class="section-title">
          <h1>
            {{ "booking.user.forgot_password" | translate }}
          </h1>
        </div>
      </div>
      <div class="section-content">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <p-card
            subheader="{{ 'booking.user.forgot_password_desc' | translate }}"
            [style]="{ width: '360px' }"
          >
            <div class="flex flex-column gap-3">
              <div class="flex flex-column gap-2 w-full">
                <label for="email">{{ "auth.email" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-at"></i>
                  </p-inputGroupAddon>
                  <input
                    type="email"
                    pInputText
                    placeholder="name@mail.com"
                    formControlName="email"
                    [ngClass]="{
                      'ng-invalid ng-dirty':
                        forgotPasswordForm.get('email')?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>
            </div>
            <p
              *ngIf="message"
              class="message mt-4 text-center"
              [ngClass]="{
                'text-green-300': requestSuccess,
                'text-red-300': !requestSuccess
              }"
            >
              {{ message | translate }}
            </p>
            <ng-template pTemplate="footer">
              <div class="flex gap-3 mt-1">
                <p-button
                  label="{{ 'booking.user.send' | translate }}"
                  class="w-full"
                  styleClass="w-full"
                  type="submit"
                  [loading]="loading"
                />
              </div>
            </ng-template>
          </p-card>
        </form>

        <a routerLink="/forgot-password" class="forgot-password">{{
          "booking.cart.forgot_password" | apiTranslation
        }}</a>
      </div>
    </div>
    <div class="section">
      <app-footer></app-footer>
    </div>
  </div>
</div>

<p-toast [position]="'top-center'"></p-toast>
