import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { Branch } from '../../branch/util/branch.model';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { User } from '../util/user.model';
import { Card } from '../util/card.model';
import { authApis } from '../util/auth.apis';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  customHttp = inject(CustomHttpClientService);

  login(email: string, password: string) {
    return this.customHttp
      .post<{ access_token: string; success: boolean; user: User }>(
        authApis.login,
        { email: email, password: password }
      )
      .pipe(
        first(),
        map((res) => {
          const user = new User();
          Object.assign(user, res.user);
          return { access_token: res.access_token, success: res.success, user };
        })
      );
  }

  register(user: User) {
    return this.customHttp.post<User>(authApis.register, user).pipe(
      first(),
      map((res) => {
        const user = new User();
        Object.assign(user, res);
        return user;
      })
    );
  }

  logout() {
    return this.customHttp.post(authApis.logout, {}).pipe(first());
  }

  forgotPassword(email: string) {
    return this.customHttp
      .post<any>(authApis.forgotPassword, { email: email })
      .pipe(first());
  }

  changePassword(
    current_password: string,
    new_password: string,
    new_password_confirmation: string
  ) {
    return this.customHttp
      .post<any>(authApis.changePassword, {
        current_password: current_password,
        new_password: new_password,
        new_password_confirmation: new_password_confirmation,
      })
      .pipe(first());
  }

  resendVerificationEmail(email: string) {
    return this.customHttp
      .post<any>(authApis.resendVerificationEmail, { email: email })
      .pipe(first());
  }
}
