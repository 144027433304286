import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
// import { NgXCreditCardsModule } from 'ngx-credit-cards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SkeletonModule } from 'primeng/skeleton';
import {
  Observable,
  Subscription,
  catchError,
  concatMap,
  delay,
  finalize,
  forkJoin,
  interval,
  of,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { CheckoutService } from '../../data-access/checkout.service';
import { Transaction } from '../../util/transaction.model';
import { CartService } from '../../data-access/cart.service';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { FacebookPixelEventTrackerService } from '../../../core/services/facebook-pixel-event-tracker.service';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleEventTrackerService } from '../../../core/services/google-event-tracker.service';
import { SnapEventTrackerService } from '../../../core/services/snap-event-tracker.service';
import { TiktokEventTrackerService } from '../../../core/services/tiktok-event-tracker.service';
import { ButtonModule } from 'primeng/button';
import { environment } from '../../../../environments/environment';
import { checkoutApis } from '../../util/checkout.apis';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AsyncPipe,
    DecimalPipe,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    NgClass,
    RouterModule,
    TranslateModule,
    SkeletonModule,
    FooterComponent,
    HeaderComponent,
    ApiTranslationPipe,
    TranslateModule,
    ButtonModule,
  ],
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {
  checkoutService = inject(CheckoutService);
  cartService = inject(CartService);
  router = inject(Router);
  _cd = inject(ChangeDetectorRef);
  transactions$: Observable<Transaction[] | null> | undefined;
  transactions!: Transaction[];
  ticketPaths: { [key: string]: string | null } = {};
  paymentId!: string;
  message!: string;
  isLoading: boolean = false;
  paymentFailed: boolean | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paymentId =
      this.route.snapshot.queryParams['id'] ||
      this.route.snapshot.queryParams['payment_id'] ||
      this.route.snapshot.queryParams['orderId'];

    const transactions = this.route.snapshot.queryParams['transactions'];

    const paymentMode = transactions
      ? 'apple_pay'
      : this.route.snapshot.queryParams['payment_id']
      ? 'tabby'
      : this.route.snapshot.queryParams['orderId']
      ? 'tamara'
      : 'credit/debit';

    const cartItems = this.cartService.getItems();
    const itemsNum = cartItems.reduce((sum, obj) => sum + obj.quantity, 0);
    const itemIds = cartItems.map((obj) => `${obj.ProductId}`);
    const userInfo = JSON.parse(
      localStorage.getItem('userInfo') ??
        JSON.stringify({
          email: 'null',
          firstName: 'null',
          lastName: 'null',
          phoneNumber: 'null',
        })
    );

    this.message = this.route.snapshot.queryParams['message'];

    this.isLoading = true;

    interval(1000)
      .pipe(
        concatMap(() =>
          this.checkoutService.getTransactionByPayment(this.paymentId).pipe(
            delay(1000),
            catchError(() => of({ status: false, transactions: [] }))
          )
        ),
        takeWhile(
          (response) => response === null || response.status === false,
          true
        ),
        tap((response) => {
          if (
            response &&
            response.status === true &&
            response.transactions.length > 0
          ) {
            this.transactions = response.transactions;
            this.startCheckingTickets();
            this.paymentFailed = false;
            GoogleEventTrackerService.trackEvent('purchase', {
              payment_mode: paymentMode,
              items: cartItems,
            });
            FacebookPixelEventTrackerService.trackEvent('purchase', {
              payment_mode: paymentMode,
              items: cartItems,
            });
            SnapEventTrackerService.trackEvent('PURCHASE', {
              price: this.transactions.reduce(
                (sum, obj) => sum + obj.TransactionNetAmount,
                0
              ),
              currency: 'SAR',
              transaction_id: this.transactions[0].TransactionId,
              item_ids: itemIds,
              item_category: 'Malahi',
              number_items: itemsNum,
              uuid_c1: this.transactions[0].Guid,
              delivery_method: paymentMode,
              user_email: userInfo.email,
              user_phone_number: userInfo.phoneNumber,
              firstname: userInfo.firstName,
              lastname: userInfo.lastName,
            });
            this.completeTikTokEvent(
              this.transactions,
              paymentMode == 'apple_pay' ? 'applepay' : paymentMode
            );
            this.cartService.clearCart();
          }
        }),
        catchError((error) => {
          if (error.status == 402) {
            this.paymentFailed = true;
          }
          if (error.status == 500) {
            this.router.navigate(['/']);
          }
          console.error('Transaction failed', error);
          return of(null);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  downloadFile(fileName: string, fileUrl: string) {
    let link = document.createElement('a');
    link.download = fileName;
    link.target = '_blank';
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadTicket(path: string) {
    const fileUrl = `${environment.baseUrl}${checkoutApis.downloadTicket(
      path.split('.pdf')[0]
    )}`;
    this.downloadFile('Ticket', fileUrl);
  }

  completeTikTokEvent(transactions: any[], type: string) {
    let tiktokContent: any[] = [];
    this.transactions.forEach((transaction) => {
      transaction.TransactionLinesDTOList.forEach((line) => {
        let existingItem = tiktokContent.find(
          (item) => item.content_id === `${line.ProductId}`
        );
        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          tiktokContent.push({
            content_id: `${line.ProductId}`,
            content_type: 'product',
            content_category: `${transaction.SiteId}`,
            content_name: `${line.ProductName}`,
            quantity: 1,
            price: `${line.Amount}`,
          });
        }
      });
    });

    TiktokEventTrackerService.trackEvent('CompletePayment', {
      contents: tiktokContent,
      value: transactions
        .reduce((sum, obj) => sum + obj.TransactionNetAmount, 0)
        .toString(),
      currency: 'SAR',
      description: type,
    });
  }

  startCheckingTickets() {
    let index = 0;
    this.transactions.forEach((transaction) => {
      this.ticketPaths[transaction.TransactionOTP ?? ''] = null;
      // this.downloadReceipt(index);
      index++;
    });

    // each 1 second call pingTicket for each transaction with (this.paymentId, and transaction.TransactionOTP)
    interval(1000)
      .pipe(
        concatMap(() =>
          forkJoin(
            this.transactions
              .filter((transaction) => transaction.TransactionOTP)
              .map((transaction) =>
                this.checkoutService.pingTicket(
                  this.paymentId,
                  transaction.TransactionOTP ?? ''
                )
              )
          ).pipe(
            delay(1000),
            catchError(() => of([]))
          )
        ),
        takeWhile(() => {
          return this.transactions.some(
            (transaction) =>
              transaction.TransactionOTP &&
              this.ticketPaths[transaction.TransactionOTP] === null
          );
        }),
        tap((res) => {
          res.forEach((path, index) => {
            if (path) {
              const otp = this.transactions[index].TransactionOTP ?? '';
              const fullPath = `${environment.baseStorage}/merged_pdfs/${path}`;
              this.ticketPaths[otp] = path;
              // Automatically download the PDF file
              this.downloadTicket(path);
              this.downloadFile('Ticket', fullPath);
              this._cd.markForCheck();
            }
          });
        })
      )
      .subscribe();
  }
}
