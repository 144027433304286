<div class="section">
  <ng-container *ngIf="content$ | async as content; else contentLoading">
    <div class="section-content" [innerHTML]="content"></div>
  </ng-container>
  <ng-template #contentLoading>
    <p-skeleton width="100%" height="30vh"></p-skeleton>
  </ng-template>
</div>
<div class="section">
  <app-footer></app-footer>
</div>
