<div class="orders-container">
  <ng-container *ngIf="!isLoading; else ordersLoading">
    <ng-container *ngIf="orders.length > 0; else noOrders">
      <div *ngFor="let order of orders" class="order-box">
        <div class="order-header">
          <div class="attraction-info">
            <app-ngx-ik-image-cdn
              class="attraction-icon"
              [path]="order.attraction.icon"
            ></app-ngx-ik-image-cdn>
            <!-- <img
              [src]="order.attraction.icon"
              alt="{{ order.attraction.name }}"
              class="attraction-icon"
            /> -->
            <h3>{{ order.attraction.name }}</h3>
          </div>
          <!-- <p-tag
            [value]="'profile.order.completed' | translate"
            [severity]="getStatusSeverity(order.status)"
          >
          </p-tag> -->
          <button
            pButton
            class="mx-2 gap-2"
            [disabled]="order.ticket_path == null || order.ticket_path == ''"
            (click)="downloadTicket(order.ticket_path)"
          >
            <ng-container
              *ngIf="
                order.ticket_path != null && order.ticket_path != '';
                else loading
              "
            >
              {{ "booking.thankyou.download_ticket" | translate }}
              <i class="pi pi-download"></i>
            </ng-container>
            <ng-template #loading>
              {{ "booking.thankyou.generating_ticket" | translate }}...
              <i class="mi-2 pi pi-spin pi-spinner"></i>
            </ng-template>
          </button>
        </div>

        <p-divider class="block mx-3 p-element"></p-divider>

        <div class="order-content">
          <div class="order-details">
            <div class="detail-item">
              <span class="label">{{ "profile.order.date" | translate }}:</span>
              <span>{{ order.created_at | date : "yyyy-MM-dd hh:mm" }}</span>
            </div>
            <div class="detail-item">
              <span class="label"
                >{{ "profile.order.total" | translate }}:</span
              >
              <span
                >{{
                  order.response.TransactionNetAmount | number : "1.2-2"
                }}
                SAR</span
              >
            </div>
            <div class="detail-item">
              <span class="label"
                >{{ "profile.order.payment_method" | translate }}:</span
              >
              <span class="payment-method-container">
                <img
                  [src]="
                    order.payment_mode === 'credit/debit'
                      ? 'assets/images/master-card.png'
                      : order.payment_mode === 'tamara'
                      ? 'assets/images/tamara.png'
                      : order.payment_mode === 'tabby'
                      ? 'assets/images/tabby-new.webp'
                      : order.payment_mode === 'applepay'
                      ? 'assets/images/Apple_Pay.png'
                      : ''
                  "
                  [alt]="order.payment_mode"
                  class="payment-method-icon"
                />
                {{
                  order.payment_mode === "credit/debit"
                    ? "Credit Card"
                    : order.payment_mode === "tamara"
                    ? ""
                    : order.payment_mode === "tabby"
                    ? ""
                    : order.payment_mode === "applepay"
                    ? "Apple Pay"
                    : order.payment_mode
                }}
              </span>
            </div>
            <div class="detail-item">
              <span class="label">OTP:</span>
              <span>{{ order.response.TransactionOTP }}</span>
            </div>
          </div>

          <div class="products-section">
            <span class="label"
              >{{ "profile.order.products" | translate }}:</span
            >
            <div class="flex justify-content-between align-items-center">
              <div class="products-list">
                <span
                  *ngFor="let product of order.products"
                  class="product-item"
                >
                  {{ product.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noOrders>
    <div class="no-orders-message">
      <h2>{{ "profile.order.no_orders" | translate }}</h2>
      <p>{{ "profile.order.no_orders_description" | translate }}</p>
      <button pButton class="mt-5" type="button" (click)="navigateToShop()">
        {{ "profile.order.browse_products" | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #ordersLoading>
    <div *ngFor="let _ of [1, 2]" class="order-box">
      <div class="order-header">
        <div class="attraction-info">
          <p-skeleton shape="circle" width="40px" height="40px"></p-skeleton>
          <p-skeleton width="200px" height="24px"></p-skeleton>
        </div>
        <p-skeleton width="100px" height="24px"></p-skeleton>
      </div>

      <p-divider class="block mx-3 p-element"></p-divider>

      <div class="order-content">
        <div class="order-details">
          <div *ngFor="let _ of [1, 2, 3, 4]" class="detail-item">
            <p-skeleton width="120px" height="20px"></p-skeleton>
            <p-skeleton width="150px" height="20px"></p-skeleton>
          </div>
        </div>

        <div class="products-section">
          <p-skeleton width="100px" height="20px"></p-skeleton>
          <div class="flex justify-content-between align-items-center mt-2">
            <p-skeleton width="200px" height="20px"></p-skeleton>
            <p-skeleton width="150px" height="36px"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
