import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { MenuItem, MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProfileService } from '../../data-access/profile.service';
import { User } from '../../util/user.model';
import {
  NgxPhoneNumberInputModule,
  PhoneInputComponent,
} from 'ngx-lib-phone-input';
import { CountryListItemType, countries } from 'country-list-json';
import { AuthService } from '../../data-access/auth.service';
import { UserStateService } from '../../data-access/user-state.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    RouterModule,
    ToastModule,
    NgIf,
    NgFor,
    ApiTranslationPipe,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    TranslateModule,
    CalendarModule,
    DividerModule,
    SkeletonModule,
    NgxPhoneNumberInputModule,
    AsyncPipe,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [MessageService],
})
export class ProfileComponent implements OnInit {
  _cd = inject(ChangeDetectorRef);
  router = inject(Router);
  translateService = inject(TranslateService);
  profileService = inject(ProfileService);
  authService = inject(AuthService);
  messageService = inject(MessageService);
  userState = inject(UserStateService);

  @ViewChild(PhoneInputComponent, { static: false })
  phoneInputComponent!: PhoneInputComponent;

  pageLoading = false;
  loading = false;
  passwordLoading = false;

  user: User | undefined;

  profileForm!: FormGroup;
  passwordForm!: FormGroup;
  errors: any = {};
  errorKeys: any[] = [];
  passwordErrors: any = {};
  passwordErrorKeys: any[] = [];
  yearRange = '1950:2022';
  dateFormat = 'yy-mm-dd';

  visible: boolean = false;
  allCountries: CountryListItemType[] = [];

  constructor(private _fb: FormBuilder) {
    this.allCountries = countries
      .filter((country) => country.code !== 'IL')
      .map((country) => {
        if (country.code === 'PS') {
          return { ...country, name: 'Palestine' };
        }
        return country;
      });
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  detectPhoneNumber(): void {
    setTimeout(() => {
      if (!this.user) return;
      if (!this.user.phone_number || this.user.phone_number == '') {
        this.phoneInputComponent.countrySelect(
          this.allCountries.find((country) => country.code === 'SA')!
        );
      } else {
        let countryCode = this.user.phone_number.slice(
          0,
          this.user.phone_number.length - 9
        );
        if (countryCode == '+972') countryCode = '+970';
        this.phoneInputComponent.countriesWithCodes = this.allCountries;
        try {
          this.phoneInputComponent.countrySelect(
            this.allCountries.find(
              (country) => country.dial_code === countryCode
            )!
          );
        } catch (e) {
          this.phoneInputComponent.countrySelect(
            this.allCountries.find((country) => country.code === 'SA')!
          );
        }

        this.profileForm.get('phone_number')?.setValue(this.user.phone_number);
      }

      this.phoneInputComponent.registerOnChange((event: any) => {
        this.registerOnChange(event);
      });

      this._cd.detectChanges();
    }, 50);
  }

  registerOnChange(number: any) {
    const phoneNumberControl = this.profileForm.get('phone_number');
    if (phoneNumberControl?.value != number) {
      phoneNumberControl?.setValue(number);
      return;
    }
  }

  getUserInfo(force: boolean = false) {
    this.pageLoading = true;

    if (force) {
      localStorage.removeItem('currentUser');
    }
    // Try to get from localStorage first
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      this.user = user;
      this.setupForms(user);
      this.pageLoading = false;
      this._cd.markForCheck();
      return;
    }

    // If not in localStorage, fetch from API
    this.profileService
      .getUserInfo()
      .subscribe((user) => {
        this.user = user;
        // Store in localStorage and currentUser
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.userState.setUser(user);
        this.setupForms(user);
      })
      .add(() => {
        this.pageLoading = false;
        this._cd.markForCheck();
      });
  }

  private setupForms(user: User) {
    this.profileForm = this._fb.group({
      name: [
        user.name,
        [Validators.required, Validators.pattern('^[a-zA-Z ]*$')],
      ],
      phone_number: [user.phone_number, [Validators.required]],
      email: [user.email, [Validators.required, Validators.email]],
      date_of_birth: [new Date(user.date_of_birth), [Validators.required]],
    });

    this.passwordForm = this._fb.group({
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      new_password_confirmation: ['', [Validators.required]],
    });
    this._cd.detectChanges();
    this.detectPhoneNumber();
  }

  updateMyInfo() {
    if (!this.isValidNumber()) {
      this.errors = {
        phone_number: [
          this.translateService.instant(
            'profile.errors.phone_number_invalid_length'
          ),
        ],
      };
      this.errorKeys = Object.keys(this.errors);
      return;
    }

    const formData = this.profileForm.value;
    formData.date_of_birth = this.formatDate(formData.date_of_birth);

    this.loading = true;
    this.profileService
      .updateMyInfo(this.profileForm.value)
      .subscribe(
        () => {
          this.getUserInfo(true);
          this.errors = {};
          this.errorKeys = [];

          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('profile.updated'),
            detail: this.translateService.instant('profile.updated_desc'),
          });
        },
        (err) => {
          this.errors = err.error.errors;
          this.errorKeys = Object.keys(this.errors);
        }
      )
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });
  }

  changePassword() {
    this.passwordForm.markAllAsTouched();
    if (this.passwordForm.invalid) {
      this.passwordErrors = {
        new_password_confirmation: [
          this.translateService.instant('profile.errors.fill_all_fields'),
        ],
      };
      this.passwordErrorKeys = Object.keys(this.passwordErrors);
      return;
    }
    if (
      this.passwordForm.value.new_password !=
      this.passwordForm.value.new_password_confirmation
    ) {
      this.passwordErrors = {
        new_password_confirmation: [
          this.translateService.instant(
            'profile.errors.password_confirmation_not_match'
          ),
        ],
      };
      this.passwordErrorKeys = Object.keys(this.passwordErrors);
      return;
    }

    this.passwordLoading = true;
    this.authService
      .changePassword(
        this.passwordForm.value.current_password,
        this.passwordForm.value.new_password,
        this.passwordForm.value.new_password_confirmation
      )
      .subscribe(
        () => {
          this.passwordErrors = {};
          this.passwordErrorKeys = [];
          this.passwordForm.reset();

          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('profile.updated'),
            detail: this.translateService.instant('password.updated_desc'),
          });
        },
        (err) => {
          this.passwordErrors = err.error.errors;
          this.passwordErrorKeys = Object.keys(this.passwordErrors);
        }
      )
      .add(() => {
        this.passwordLoading = false;
        this._cd.markForCheck();
      });
  }

  isValidNumber() {
    const input = this.profileForm.get('phone_number');
    if (!this.phoneInputComponent) {
      return true;
    }
    const value = input?.value.replace(' ', '');
    if (value != input?.value) {
      input?.setValue(value);
    }
    const dialCode = this.phoneInputComponent.selectedCountry.dial_code;
    return value.length - dialCode.length == 9 && input?.valid;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  isErrors() {
    return Object.keys(this.errors).length > 0;
  }

  isPasswordErrors() {
    return Object.keys(this.passwordErrors).length > 0;
  }
}
