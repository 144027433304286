import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { CartService } from '../../../booking/data-access/cart.service';
import { TruncatePipe } from '../../../core/pipes/truncate/truncate.pipe';
import { CardComponent } from '../../../core/widgets/card/card.component';
import { Product } from '../../util/product.model';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { LanguageService } from '../../../core/services/language-service/language.service';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    NgStyle,
    CardComponent,
    NgTemplateOutlet,
    NgIf,
    NgClass,
    ButtonModule,
    RippleModule,
    TruncatePipe,
    ToastModule,
    ApiTranslationPipe,
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
  providers: [MessageService],
})
export class ProductCardComponent {
  @Input({ required: true }) product!: Product;
  @Input() active: boolean = false;
  @Input() color: string = '';
  @Input() minimal: boolean = false;

  router = inject(Router);
  cartService = inject(CartService);
  messageService = inject(MessageService);
  languageService = inject(LanguageService);

  addToCart(product: Product, event: MouseEvent) {
    event.stopPropagation();
    const isItemAdded: boolean = this.cartService.addItem(product);
    this.messageService.add({
      key: 'confirm',
      severity: isItemAdded ? 'success' : 'error',
      summary: this.languageService.translate(
        isItemAdded ? 'products.toast.added' : 'products.toast.cart_add_error'
      ),
    });
  }

  goToProductDetails(product: Product) {
    this.router.navigate(['/view/' + product.localProduct.product_id]);
  }

  onConfirm() {
    this.router.navigate(['/cart']);
  }
}
