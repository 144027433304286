export class Transaction {
  TransactionId!: number;
  TransactionDate!: string;
  TransactionAmount!: number;
  TransactionDiscountPercentage!: number | null;
  TransactionDiscountAmount!: number;
  TaxAmount!: number;
  TransactionNetAmount!: number;
  PosMachine!: string;
  UserId!: number;
  PaymentMode!: number;
  PaymentModeName!: string | null;
  CashAmount!: number | null;
  CreditCardAmount!: number | null;
  GameCardAmount!: number | null;
  PaymentReference!: string | null;
  PrimaryCardId!: number;
  OrderId!: number;
  POSTypeId!: number;
  TransactionNumber!: string;
  TransactionOTP!: string | null;
  Remarks!: string | null;
  POSMachineId!: number;
  OtherPaymentModeAmount!: number | null;
  Status!: string;
  TransactionProfileId!: number;
  LastUpdateTime!: string;
  LastUpdatedBy!: string | null;
  TokenNumber!: string | null;
  OriginalSystemReference!: string | null;
  CustomerId!: number;
  ExternalSystemReference!: string | null;
  ReprintCount!: number;
  OriginalTransactionId!: number;
  OrderTypeGroupId!: number;
  TableNumber!: string | null;
  Paid!: boolean | null;
  UserName!: string | null;
  CreatedBy!: number;
  CreationDate!: string;
  Guid!: string | null;
  SynchStatus!: boolean;
  SiteId!: number;
  MasterEntityId!: number;
  Selected!: boolean;
  Tickets!: any | null; // TODO: CHECK
  Receipt!: any | null; // TODO: CHECK
  TicketsHTML!: string | null;
  ReceiptHTML!: string | null;
  VisitDate!: string | null;
  ApplyVisitDate!: boolean;
  IsChanged!: boolean;
  IsChangedRecursive!: boolean;
  TransactionLinesDTOList!: any[]; // TODO: CHECK;
  TrxPaymentsDTOList!: any[]; // TODO: CHECK
  DiscountsSummaryDTOList!: any[]; // TODO: CHECK
  DiscountApplicationHistoryDTOList!: any | null; // TODO: CHECK
  PrimaryCard!: string;
  ReceiptDTO!: any | null; // TODO: CHECK
  TicketDTOList!: any[]; // TODO: CHECK
  TicketPrinterMapDTOList!: any[]; // TODO: CHECK
  CustomerName!: string | null;
  CommitTransaction!: boolean;
  SaveTransaction!: boolean;
  CloseTransaction!: boolean;
  ApplyOffset!: boolean;
  PaymentProcessingCompleted!: boolean;
  ReverseTransaction!: boolean;
  CustomerIdentifier!: string | null;
  TrxPOSPrinterOverrideRulesDTO!: any | null; // TODO: CHECK
  TransctionOrderDispensingDTO!: any | null; // TODO: CHECK
  branchName!: string;
  logId?: number;
  logCartId?: number;
}
