import {
  AsyncPipe,
  DecimalPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { Product } from '../../../products/util/product.model';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, Subscription, debounceTime, filter } from 'rxjs';
import { AllowNumbersAndLettersDirective } from '../../../directives/allow-numbers-and-letters.directive';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../data-access/auth.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    RouterModule,
    FooterComponent,
    HeaderComponent,
    ToastModule,
    NgIf,
    ApiTranslationPipe,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    TranslateModule,

    // AsyncPipe,
    RippleModule,
    NgClass,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService],
})
export class ForgotPasswordComponent implements OnInit {
  authService = inject(AuthService);
  _cd = inject(ChangeDetectorRef);

  forgotPasswordForm!: FormGroup;
  requestSuccess = false;
  loading = false;
  message = '';

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    this.loading = true;
    this.authService
      .forgotPassword(this.forgotPasswordForm.value.email)
      .subscribe(
        (res) => {
          this.message = res.message;
          this.requestSuccess = true;
        },
        (err) => {
          this.requestSuccess = false;
          this.message = err.error.message;
        }
      )
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });
  }

  sendEmail() {
    // console.log(this.forgotPasswordForm.controls);
  }
}
