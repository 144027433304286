import { Injectable, inject } from '@angular/core';
import { first, map, shareReplay } from 'rxjs';
import { CustomHttpClientService } from '../../services/custom-http-client.service';
import { FooterItem } from './footer-item.model';
import { footerApi } from './footer.api';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  customHttp = inject(CustomHttpClientService);
  footerInfo$ = this.getFooterInfo();

  private getFooterInfo() {
    return this.customHttp.get<FooterItem[]>(footerApi.getFooterInfo).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new FooterItem();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res;
      }),
      shareReplay(1)
    );
  }
}
