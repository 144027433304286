import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LanguageService } from '../services/language-service/language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  languageService = inject(LanguageService);
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const language = this.languageService.getLanguage();
    const headers = { language };
    req = req.clone({ setHeaders: headers });

    return next.handle(req).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
        }

        return res;
      })
    );
  }
}
