import { Product } from '../../products/util/product.model';

export class Branch {
  id!: number;
  siteId!: number;
  name!: string;
  icon!: string;
  color?: string;
  images?: string[];
  video?: string;
  is_video?: boolean;
  products?: Product[];
  address1?: string;
  address2?: string;
  call_us?: string;
  email_us?: string;
  location?: { lat: number; lng: number };
}
