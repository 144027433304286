import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { LqipOptions } from 'imagekitio-angular/lib/utility/ik-type-def-collection';

@Component({
  selector: 'app-ngx-ik-image-cdn',
  standalone: true,
  imports: [NgIf, ImagekitioAngularModule],
  template: `
    <ng-container *ngIf="transformation.length != 0">
      <ik-image
        [path]="path"
        [transformation]="transformation"
        [lqip]="lqip"
      ></ik-image>
    </ng-container>
  `,
})
export class NgxIkImageCdnComponent {
  @Input('path') path!: string;
  @Input('transformation') transformation: Partial<{
    [key: string]: string;
  }>[] = [];
  @Input('transformationPosition') transformationPosition!: string;
  @Input('lqip') lqip: LqipOptions = { active: true };
  @Input('alt') alt?: string = 'malahi-stock-images';
  @Input('modification') modification: boolean = true;
  @Input('upScale') upScale: number = 1.5;

  @Input('width') width: string = '';
  @Input('height') height: string = '';

  screenWidth!: number;

  constructor(private el: ElementRef, private _cd: ChangeDetectorRef) {
    this.onResize();
  }

  ngAfterViewInit() {
    if (!this.width.length) {
      this.width = String(this.getImageBounding().width);
    }
    if (!this.height.length) {
      this.height = String(this.getImageBounding().height);
    }

    if (this.modification) {
      this.transformation.push({
        width: String(Math.ceil(Number(this.width) * this.upScale)),
        height: String(Math.ceil(Number(this.height) * this.upScale)),
        raw: `c-at_least`,
      });
    }

    this._cd.detectChanges();
  }

  getImageBounding(): DOMRect {
    return this.el.nativeElement.parentElement.getBoundingClientRect();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1920) {
      this.lqip.quality = 100;
    }
    if (this.screenWidth <= 1440) {
      this.lqip.quality = 80;
    }
    if (this.screenWidth <= 1280) {
      this.lqip.quality = 40;
    }
    if (this.screenWidth <= 500) {
      this.lqip.quality = 20;
    }
  }
}
