import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

const script = document.createElement('script');
script.defer = true;
script.type = 'text/javascript';
script.src = environment.tamaraWidgetUrl;
document.body.appendChild(script);

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
