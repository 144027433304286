import {
  AsyncPipe,
  DecimalPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../data-access/auth.service';
import { environment } from '../../../../environments/environment';
import { UserStateService } from '../../data-access/user-state.service';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    RouterModule,
    FooterComponent,
    HeaderComponent,
    ToastModule,
    NgIf,
    ApiTranslationPipe,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    TranslateModule,

    // AsyncPipe,
    RippleModule,
    NgClass,
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [MessageService],
})
export class SignInComponent implements OnInit {
  authService = inject(AuthService);
  _cd = inject(ChangeDetectorRef);
  route = inject(ActivatedRoute);
  router = inject(Router);
  userState = inject(UserStateService);

  signInForm!: FormGroup;
  loading = false;
  showResendEmail = false;
  lastEnteredEmail: string | null = null;
  message: string | null = null;
  errorMessage: string | null = null;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.signInForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    // Get message from either route params or query params
    this.route.queryParamMap.subscribe((params) => {
      this.message =
        params.get('message') || params.get('registered')
          ? 'User registered successfully. Please check your email for verification link.'
          : null;
    });
  }

  resendVerification() {
    if (!this.lastEnteredEmail) return;
    this.authService
      .resendVerificationEmail(this.lastEnteredEmail)
      .subscribe((res) => {
        this.showResendEmail = false;
      });
  }

  onSubmit(): void {
    if (this.signInForm.invalid) {
      this.markFormGroupTouched(this.signInForm);
      return;
    }

    this.lastEnteredEmail = this.signInForm.get('email')?.value;

    this.loading = true;
    this.authService
      .login(
        this.signInForm.get('email')?.value,
        this.signInForm.get('password')?.value
      )
      .subscribe(
        (res: any) => {
          localStorage.setItem(environment.jwtKey, res.access_token);
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          this.userState.setUser(res.user);

          this.router.navigate(['/dashboard/cards']);
        },
        (err) => {
          // this.errors = err.error.errors;
          // this.errorKeys = Object.keys(this.errors);
          this.errorMessage = err.error.message;
          if (this.errorMessage?.includes('verify your email')) {
            this.showResendEmail = true;
          }
        }
      )
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });

    // Handle form submission
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }

  sendEmail() {
    // console.log(this.signInForm.controls);
  }
}
