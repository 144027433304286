import { DecimalPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { SkeletonModule } from 'primeng/skeleton';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProfileService } from '../../data-access/profile.service';
import { User } from '../../util/user.model';
import { InputMaskModule } from 'primeng/inputmask';
import { Card } from '../../util/card.model';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { ProductsService } from '../../../products/data-access/products.service';
import { AllowNumbersAndLettersDirective } from '../../../directives/allow-numbers-and-letters.directive';
import {
  NgxPhoneNumberInputModule,
  PhoneInputComponent,
} from 'ngx-lib-phone-input';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Branch } from '../../../branch/util/branch.model';
import { AuthService } from '../../data-access/auth.service';

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [
    RouterModule,
    ToastModule,
    NgIf,
    NgFor,
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    TranslateModule,
    RippleModule,
    CalendarModule,
    DividerModule,
    InputMaskModule,
    NgxIkImageCdnComponent,
    DialogModule,
    SkeletonModule,
    DropdownModule,
    AllowNumbersAndLettersDirective,
    NgxPhoneNumberInputModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    DecimalPipe,
  ],
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CardsComponent implements OnInit {
  _cd = inject(ChangeDetectorRef);
  router = inject(Router);
  route = inject(ActivatedRoute);
  translateService = inject(TranslateService);
  profileService = inject(ProfileService);
  authService = inject(AuthService);
  productsService = inject(ProductsService);
  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);

  @ViewChild(PhoneInputComponent, { static: false })
  phoneInputComponent!: PhoneInputComponent;

  pageLoading = false;
  loading = false;
  passwordLoading = false;
  selectedPage = 'cards';

  user: User | undefined;
  cards: Card[] = [];
  branches: { [key: string]: Branch } = {};

  cardNumber!: string;

  addCardError: string = '';

  visible: boolean = false;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.getBranches();
    this.getUserInfo();
    this.getAllCards();
  }

  getBranches() {
    this.pageLoading = true;
    this.productsService
      .getBranches()
      .subscribe((res) => {
        this.branches = res.reduce((acc: any, branch) => {
          acc[branch.siteId] = branch;
          return acc;
        }, {});
        console.log(this.branches);
      })
      .add(() => {
        this.pageLoading = false;
        this._cd.markForCheck();
      });
  }

  showDialog() {
    this.cardNumber = '';
    this.addCardError = '';
    this.visible = true;
  }

  getUserInfo() {
    this.pageLoading = true;

    // Try to get from localStorage first
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      this.user = JSON.parse(storedUser);
      this.pageLoading = false;
      this._cd.markForCheck();
      return;
    }

    // // If not in localStorage, fetch from API
    // this.profileService
    //   .getUserInfo()
    //   .subscribe((user) => {
    //     this.user = user;
    //     // Store in localStorage
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //   })
    //   .add(() => {
    //     this.pageLoading = false;
    //     this._cd.markForCheck();
    //   });
  }

  getAllCards() {
    this.pageLoading = true;
    this.profileService
      .getAllCards()
      .subscribe((cards) => {
        this.cards = cards;
      })
      .add(() => {
        this.pageLoading = false;
        this._cd.markForCheck();
      });
  }

  confirmDeleteCard(event: Event, id: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translateService.instant('confirmation.delete_card_desc'),
      header: this.translateService.instant('confirmation.delete'),
      acceptLabel: this.translateService.instant('confirmation.yes'),
      rejectLabel: this.translateService.instant('confirmation.no'),
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.deleteCard(id);
      },
      reject: () => {},
    });
  }

  deleteCard(id: number) {
    this.loading = true;
    this.profileService
      .deleteCard(id)
      .subscribe(() => {
        this.messageService.add({
          severity: 'info',
          summary: this.translateService.instant('confirmation.confirmed'),
          detail: this.translateService.instant('confirmation.card_deleted'),
        });
        this.getAllCards();
      })
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });
  }

  canAddCard() {
    return this.cardNumber && this.cardNumber.length >= 8;
  }

  addCard() {
    this.loading = true;
    this.cardNumber = this.cardNumber.slice(0, 8);
    this.profileService
      .addCard(this.cardNumber)
      .subscribe(
        () => {
          this.visible = false;
          this.getAllCards();
        },
        (err) => {
          this.addCardError = err.error.error;
        }
      )
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });
  }
}
