export class EventForm {
  eventId!: number | undefined;
  fullName!: string;
  branch!: string;
  phoneNumber!: string;
  email!: string;
  message!: string;

  toJson() {
    return {
      eventId: this.eventId,
      fullName: this.fullName,
      branch: this.branch,
      phoneNumber: this.phoneNumber,
      email: this.email,
      message: this.message,
    };
  }
}
