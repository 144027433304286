export const authApis = {
  register: '/register',
  login: '/login',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  changePassword: '/changePassword',
  resendVerificationEmail: '/resend-verification-email',
  resetPassword: '/reset-password',
  userInfo: '/profile',
  getUserCards: '/getUserCards',
  getUserCardsWithoutDetails: '/getUserCardsWithoutDetails',
  addCard: '/addCard',
  deleteCard: '/deleteCard',
  getUserBookings: '/getUserBookings',
};
