import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { User } from '../util/user.model';
import { authApis } from '../util/auth.apis';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  customHttp = inject(CustomHttpClientService);

  getUserBookings() {
    return this.customHttp.get<User>(authApis.getUserBookings).pipe(
      first(),
      map((res: any) => {
        return res;
      })
    );
  }
}
