import { Injectable } from '@angular/core';

declare const snaptr: any;

@Injectable()
export class SnapEventTrackerService {
  constructor() {}

  public static trackEvent(key: string, properties: Object) {
    if (typeof snaptr === 'undefined') {
      return;
    }
    snaptr('track', key, properties);
  }
}
