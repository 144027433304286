import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, tap } from 'rxjs';
import { FooterComponent } from '../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../core/widgets/header/header.component';
import { ProductsService } from '../../products/data-access/products.service';
import { Event } from '../../products/util/event.model';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EventForm } from '../util/event-form.model';
import { EventsService } from '../data-access/events.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { LanguageService } from '../../core/services/language-service/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { Branch } from '../../branch/util/branch.model';
import { DropdownModule } from 'primeng/dropdown';
import { ApiTranslationPipe } from '../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { FacebookPixelEventTrackerService } from '../../core/services/facebook-pixel-event-tracker.service';
import { GoogleEventTrackerService } from '../../core/services/google-event-tracker.service';
import { SnapEventTrackerService } from '../../core/services/snap-event-tracker.service';
import { TiktokEventTrackerService } from '../../core/services/tiktok-event-tracker.service';

@Component({
  selector: 'app-event-form',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    NgIf,
    AsyncPipe,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    SkeletonModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    ToastModule,
    TranslateModule,
    DropdownModule,
    NgStyle,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    NgClass,
    DividerModule,
  ],
  templateUrl: './event-form.component.html',
  styleUrl: './event-form.component.scss',
  providers: [MessageService],
})
export class EventFormComponent implements OnInit {
  @Input({ transform: (id: string) => Number(id) }) id!: number;

  service = inject(ProductsService);
  eventsService = inject(EventsService);
  messageService = inject(MessageService);
  languageService = inject(LanguageService);
  event$: Observable<Event | null> | undefined;
  event!: Event | null;
  eventForm$: Observable<EventForm> | undefined;
  branches$: Observable<Branch[]> | undefined;
  branch?: Branch;
  emailForm!: FormGroup;
  isLoading: boolean = false;
  buttonClicked: boolean = false;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.event$ = this.service.getEventItem(this.id).pipe(
      tap((value) => {
        this.event = value;
        GoogleEventTrackerService.trackEvent('view_event', {
          event: this.event,
        });
        FacebookPixelEventTrackerService.trackEvent('view_event', {
          event: this.event,
        });
        SnapEventTrackerService.trackEvent('VIEW_CONTENT', {
          item_category: this.event?.name,
        });
        TiktokEventTrackerService.trackEvent('ViewContent', {
          contents: [
            {
              content_id: `${this.event?.id}`,
              content_type: 'product_group',
              content_category: 'event',
              content_name: this.event?.name,
            },
          ],
        });
      })
    );

    this.branches$ = this.service.getBranches();

    this.emailForm = this._fb.group({
      fullName: [''],
      email: ['', Validators.email],
      phoneNumber: ['', Validators.maxLength(10)],
      message: [''],
    });
  }

  sendToWhatsapp() {
    const message = `مرحبًا، أود الاستفسار عن ${this.event?.name}`;
    const url = `https://wa.me/966536517321?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, '_blank');
  }

  sendEmail() {
    this.buttonClicked = true;
    this.emailForm.get('fullName')?.markAsDirty();
    this.emailForm.get('email')?.markAsDirty();
    this.emailForm.get('phoneNumber')?.markAsDirty();
    this.emailForm.get('message')?.markAsDirty();
    if (this.emailForm.valid && this.branch) {
      const eventForm = new EventForm();
      eventForm.eventId = this.event?.id;
      eventForm.fullName = this.emailForm.get('fullName')?.value;
      eventForm.branch = this.branch?.name!;
      eventForm.email = this.emailForm.get('email')?.value;
      eventForm.phoneNumber = this.emailForm.get('phoneNumber')?.value;
      eventForm.message = this.emailForm.get('message')?.value;

      this.isLoading = true;

      this.eventForm$ = this.eventsService.sendEmail(eventForm).pipe(
        tap((value) => {
          this.messageService.add({
            severity: 'success',
            summary: this.languageService.translate('general.toast.success'),
            detail: this.languageService.translate('events.form.sent'),
          });
          GoogleEventTrackerService.trackEvent('reserve_event', {
            event: this.event,
            formData: eventForm.toJson(),
          });
          FacebookPixelEventTrackerService.trackEvent('reserve_event', {
            event: this.event,
            formData: eventForm.toJson(),
          });
          SnapEventTrackerService.trackEvent('RESERVE', {
            item_ids: [`${this.event?.id}`],
            item_category: this.event?.name,
            user_email: eventForm.email,
            user_phone_number: eventForm.phoneNumber,
            firstname: eventForm.fullName,
          });
          TiktokEventTrackerService.identify(
            eventForm.email,
            eventForm.phoneNumber
          );
          TiktokEventTrackerService.trackEvent('SubmitForm', {
            contents: [
              {
                content_id: `${this.event?.id}`,
                content_type: 'product_group',
                content_category: 'event',
                content_name: this.event?.name,
              },
            ],
          });
          this.emailForm.reset();
          this.isLoading = false;
        })
      );
      this.eventForm$.subscribe();
    }
  }

  onBranchChange(event: any) {
    this.branch = event.value;
  }
}
