import { Injectable } from '@angular/core';

declare const ttq: any;

@Injectable()
export class TiktokEventTrackerService {
  constructor() {}

  public static identify(email: string, phoneNumber: string) {
    if (typeof ttq === 'undefined') {
      return;
    }
    ttq.identify({
      email: email,
      phone_number: phoneNumber,
    });
  }

  public static trackEvent(key: string, properties: Object) {
    if (typeof ttq === 'undefined') {
      return;
    }
    ttq.track(key, properties);
  }
}
