import { MessagesModule } from 'primeng/messages';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { Message, MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { InputMaskModule } from 'primeng/inputmask';
import { AuthService } from '../../data-access/auth.service';
import {
  NgxPhoneNumberInputModule,
  PhoneInputComponent,
} from 'ngx-lib-phone-input';
import { CountryListItemType, countries } from 'country-list-json';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    RouterModule,
    FooterComponent,
    HeaderComponent,
    ToastModule,
    NgIf,
    NgFor,
    ApiTranslationPipe,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    TranslateModule,
    CalendarModule,
    InputMaskModule,
    CheckboxModule,
    MessagesModule,

    // AsyncPipe,
    RippleModule,
    NgClass,
    NgxPhoneNumberInputModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../sign-in/sign-in.component.scss'],
  providers: [MessageService, MessagesModule],
})
export class SignUpComponent implements OnInit, AfterViewInit {
  _cd = inject(ChangeDetectorRef);
  authService = inject(AuthService);
  router = inject(Router);

  @ViewChild(PhoneInputComponent) phoneInputComponent!: PhoneInputComponent;
  signUpForm!: FormGroup;
  loading = false;
  yearRange = '1950:2022';
  dateFormat = 'yy-mm-dd';
  test = true;
  messages!: Message[];
  errors: any = {};
  errorKeys: any[] = [];
  allCountries: CountryListItemType[] = [];

  constructor(private _fb: FormBuilder) {
    this.allCountries = countries
      .filter((country) => country.code !== 'IL')
      .map((country) => {
        if (country.code === 'PS') {
          return { ...country, name: 'Palestine' };
        }
        return country;
      });
  }

  ngOnInit(): void {
    this.signUpForm = this._fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z\u0600-\u06FF ]*$')],
      ],
      phone_number: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: [
        '',
        [Validators.required, this.matchValues('password')],
      ],
      date_of_birth: [
        '',
        [
          Validators.required,
          // Validators.pattern(
          //   '^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/((19|20)dd)$'
          // ),
        ],
      ],
      acceptTerms: [false, Validators.requiredTrue],
    });

    this.messages = [
      { severity: 'success', summary: 'Error', detail: 'Invalid Email' },
    ];
  }

  ngAfterViewInit(): void {
    // Access ViewChild reference here
    this.phoneInputComponent.countriesWithCodes = this.allCountries;
    this.phoneInputComponent.countrySelect(
      this.allCountries.find((country) => country.code === 'SA')!
    );

    this.phoneInputComponent.registerOnChange((event: any) => {
      this.registerOnChange(event);
    });
  }

  registerOnChange(number: any) {
    const phoneNumberControl = this.signUpForm.get('phone_number');
    if (phoneNumberControl?.value != number) {
      phoneNumberControl?.setValue(number);
      return;
    }
  }

  onSubmit() {
    if (this.signUpForm.invalid) {
      this.markFormGroupTouched(this.signUpForm);
      return;
    }

    const formData = this.signUpForm.value;
    formData.date_of_birth = this.formatDate(formData.date_of_birth);

    this.loading = true;
    this.authService
      .register(formData)
      .subscribe(
        (res: any) => {
          this.router.navigate(['/login'], {
            queryParams: {
              registered: true,
              message: res['message'],
            },
          });
          // this.cardNumberStatus[branch] = res == null ? false : true;
          // if (this.cardNumberStatus[branch]) {
          //   this.cardNumberName[branch] = res;
          // }
          // this._cd.markForCheck();
        },
        (err) => {
          this.errors = err.error.errors;
          this.errorKeys = Object.keys(this.errors);
          console.log(this.errors);
        }
      )
      .add(() => {
        this.loading = false;
        this._cd.markForCheck();
      });
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }
  sendEmail() {
    // console.log(this.signUpForm.controls);
  }

  matchValues(matchTo: string) {
    return (control: any) => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  isTermsError() {
    return (
      this.signUpForm.get('acceptTerms')?.invalid &&
      this.signUpForm.get('acceptTerms')?.touched
    );
  }

  isErrors() {
    return Object.keys(this.errors).length > 0;
  }
}
