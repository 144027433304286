export const environment = {
  production: false,

  // Api
  baseUrl: 'https://api-test.malahi.com/api',
  baseStorage: 'https://api-test.malahi.com/storage',
  domainName: 'booking-test.malahi.com',

  // Google Map
  gmapKey: 'AIzaSyA6uRh6RNt_YIXZ_97uxDSapr4uCTLbl34',

  // ImageKit
  urlEndpoint: 'https://ik.imagekit.io/Malahi',
  publicKey: 'public_Nltb9/1WEQ8YdD2dI4Z/9JP2JTQ=',

  // Moyasar
  moyasarPublicKey: 'pk_test_qnJw94qhjofa5Djmu8h1CUtC1afP9DfxqWgW4VBr',

  // Tabby
  tabbyPublicKey: 'pk_test_99d5f2bb-779a-45c3-af17-2a8e0e7d92a5',
  merchantCode: 'Malahileisure',

  // Tamara
  tamaraPublicKey: 'fad93302-271b-48fb-b69c-343288de7caa',
  tamaraWidgetUrl: 'https://cdn-sandbox.tamara.co/widget-v2/tamara-widget.js',

  // JWT
  jwtKey: 'jwtToken',
};
