import { Injectable, inject } from '@angular/core';
import { CustomHttpClientService } from '../custom-http-client.service';
import { languageApis } from './language.api';
import { first } from 'rxjs';

const DEFAULT_LANGUAGE = 'ar';
const SUPPORTED_LANGUAGES = ['ar', 'en'];

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  customHttp = inject(CustomHttpClientService);

  private language = DEFAULT_LANGUAGE;

  constructor() {
    const lang = localStorage.getItem('language') ?? DEFAULT_LANGUAGE;
    this.setLanguage(lang, false);
  }

  setLanguage(lang: string, reload: boolean = true) {
    if (SUPPORTED_LANGUAGES.includes(lang)) {
      this.language = lang;
      localStorage.setItem('language', lang);
    }

    if (reload) {
      location.reload();
    }
  }

  getLanguage() {
    return this.language;
  }

  getTranslations() {
    return this.customHttp
      .get<any[]>(languageApis.getTranslations)
      .pipe(first());
  }

  translate(item: string): string {
    const values = JSON.parse(localStorage.getItem(this.language) ?? '[]');
    return values[item] ?? '';
  }
}
