import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { Branch } from '../../branch/util/branch.model';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { Banner } from '../util/banner.model';
import { Brand } from '../util/brand.model';
import { EventCategory } from '../util/event-category.model';
import { productApis } from '../util/product.apis';
import { Product } from '../util/product.model';
import { Trending } from '../util/trending.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  customHttp = inject(CustomHttpClientService);

  getProducts(id: number, recommended: boolean = false) {
    return this.customHttp
      .get<Branch>(productApis.getProducts(id, recommended))
      .pipe(
        first(),
        map((res) => {
          const t2 = new Branch();
          Object.assign(t2, {
            ...res,
            location: res.location
              ? { lat: Number(res.location.lat), lng: Number(res.location.lng) }
              : null,
          });
          return t2;
        })
      );
  }

  getAllProducts(recommended: boolean = false) {
    return this.customHttp
      .get<Product[]>(productApis.getAllProducts(recommended))
      .pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Product();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      );
  }

  getBanners() {
    return this.customHttp.get<Banner[]>(productApis.getBanners).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new Banner();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res;
      })
    );
  }

  getEvents() {
    return this.customHttp.get<EventCategory[]>(productApis.getEvents).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new EventCategory();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res;
      })
    );
  }
  getEventItem(eventId: number) {
    return this.getEvents().pipe(
      map((categories) => {
        for (let category of categories) {
          const event = category.reservation_events.find(
            (event) => event.id === eventId
          );

          if (event) {
            return event;
          }
        }
        return null;
      })
    );
  }
  getBrands() {
    return this.customHttp.get<Brand[]>(productApis.getBrands).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new Brand();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res.sort(
          (a, b) =>
            (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE)
        );
      })
    );
  }

  getBranches() {
    return this.customHttp.get<Branch[]>(productApis.getBranches).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new Branch();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res;
      })
    );
  }

  getTrendings() {
    return this.customHttp.get<Trending[]>(productApis.getTrendings).pipe(
      first(),
      map((res) => {
        for (let i = 0; i < res?.length; i++) {
          const t2 = new Trending();
          Object.assign(t2, res[i]);
          res[i] = t2;
        }
        return res;
      })
    );
  }
}
