import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { LIBRARY_CONFIG } from '../config.token';

type GetHttpOptions = Parameters<HttpClient['get']>[1];

@Injectable({
  providedIn: 'root',
})
export class CustomHttpClientService {
  private http = inject(HttpClient);

  public get<T>(
    link: string,
    query?: string,
    options?: GetHttpOptions
  ): Observable<T> {
    query = query ?? '';
    const hasParameter: boolean = link.includes('?');
    const symbol: string = hasParameter ? '&' : '?';

    return this.http.get<T>(environment.baseUrl + link, options).pipe(
      map((x) => {
        return cloneDeep(x);
      })
    );
  }

  public post<T>(link: string, data: any): Observable<T> {
    return this.http.post<T>(environment.baseUrl + link, data).pipe<T>(
      map((x) => {
        return cloneDeep(x);
      })
    );
  }

  public delete(link: string) {
    return this.http.delete(environment.baseUrl + link);
  }

  public deleteT<T>(link: string) {
    return this.http.delete<T>(environment.baseUrl + link).pipe(
      map((x) => {
        return cloneDeep(x);
      })
    );
  }

  public put<T>(link: string, data: any): Observable<T> {
    return this.http.put<T>(environment.baseUrl + link, data).pipe(
      map((x) => {
        return cloneDeep(x);
      })
    );
  }

  public getBlob(link: string, type: string): Observable<Blob> {
    return this.http
      .get(environment.baseUrl + link, { responseType: 'blob' })
      .pipe(
        map((response) => {
          return new Blob([response], { type });
        })
      );
  }

  public getBlobStream(link: string) {
    return this.http.get(environment.baseUrl + link, {
      responseType: 'blob',
      observe: 'events',
      reportProgress: true,
    });
  }

  public postBlob<T>(
    link: string,
    files: { name: string; file: Blob; fileName: string }[],
    data: Record<string, string> = {}
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i].file, files[i].fileName);
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return this.http.post<T>(environment.baseUrl + link, formData);
  }
}
