import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  formatDate,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, finalize, tap } from 'rxjs';
import { Branch } from '../../../branch/util/branch.model';
import { CardComponent } from '../../../core/widgets/card/card.component';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ProductsService } from '../../data-access/products.service';
import { ProductCardComponent } from '../../ui/product-card/product-card.component';
import { Banner } from '../../util/banner.model';
import { Brand } from '../../util/brand.model';
import { EventCategory } from '../../util/event-category.model';
import { Product } from '../../util/product.model';
import { Trending } from '../../util/trending.model';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { LanguageService } from '../../../core/services/language-service/language.service';

@Component({
  selector: 'app-products-page',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AsyncPipe,
    NgClass,
    FormsModule,
    JsonPipe,
    SkeletonModule,
    OverlayPanelModule,
    DropdownModule,
    CalendarModule,
    NgStyle,
    RouterModule,
    FooterComponent,
    HeaderComponent,
    CardComponent,
    ProductCardComponent,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
  ],
  templateUrl: './products-page.component.html',
  styleUrl: './products-page.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProductsPageComponent implements OnInit, OnDestroy {
  router = inject(Router);
  service = inject(ProductsService);
  cdRef = inject(ChangeDetectorRef);
  languageService = inject(LanguageService);

  branches$: Observable<Branch[]> | undefined;
  banners$: Observable<Banner[]> | undefined;
  banners: Banner[] | undefined;
  products$: Observable<Product[]> | undefined;
  events$: Observable<EventCategory[]> | undefined;
  brands$: Observable<Brand[]> | undefined;
  trendings$: Observable<Trending[]> | undefined;
  activeBannerIndex = 0;
  activeEventIndex: number = 0;
  hoveredProduct: number = -1;
  scrollOverflow: boolean = false;
  interval: any;

  @ViewChild('branchSlider') branchSlider:
    | ElementRef<HTMLDivElement>
    | undefined;
  @ViewChild('scrollContainer') scrollContainer:
    | ElementRef<HTMLDivElement>
    | undefined;

  filter: {
    branch?: Branch;
  } = {};

  ngOnInit(): void {
    this.fetchProducts();
    this.fetchBanners();
    this.fetchEvents();
    this.fetchBrands();
    this.fetchTrendings();

    this.branches$ = this.branches$?.pipe(
      tap((branches) => {
        // this.filter.branch = branches[0];
      })
    );

    this.interval = setInterval(() => {
      this.goToBanner(
        this.activeBannerIndex < this.banners!.length - 1
          ? this.activeBannerIndex + 1
          : 0
      );
      this.cdRef.markForCheck();
    }, 4000);
  }

  scrollBranchSlider(direction: 'left' | 'right') {
    const slider = this.branchSlider?.nativeElement;
    if (slider) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      slider.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  exploreAllProducts() {
    this.router.navigate(['/products/all'], {
      queryParams: {
        branches: [this.filter.branch?.siteId].toString(),
      },
    });
  }

  fetchBanners() {
    this.banners$ = this.service.getBanners();
    this.banners$.subscribe((res) => {
      this.banners = res;
    });
  }

  onBranchChange(event: any) {
    this.goToBranch(event.value.siteId);
  }

  fetchProducts() {
    this.products$ = this.service.getAllProducts(true).pipe(
      finalize(() => {
        const container = this.scrollContainer?.nativeElement;
        this.cdRef.detectChanges();
        if (container) {
          this.scrollOverflow = container.scrollWidth > container.clientWidth;
        }
      })
    );
    this.branches$ = this.service.getBranches();
  }

  fetchEvents() {
    this.events$ = this.service.getEvents();
  }

  fetchBrands() {
    this.brands$ = this.service.getBrands();
  }

  fetchTrendings() {
    this.trendings$ = this.service.getTrendings();
  }

  goToBanner(index: number) {
    let carousel = document.querySelector('.carousel');
    if (carousel) {
      const carouselWidth = carousel.clientWidth;
      carousel.scrollLeft =
        carouselWidth *
        index *
        (document.documentElement.dir == 'rtl' ? -1 : 1); //-1 for rtl
      this.activeBannerIndex = index;
    }
  }

  setActiveEvent(index: number) {
    this.activeEventIndex = index;
  }

  goToBranch(branchId: number) {
    this.router.navigate(['branch', branchId]);
  }
  goToProduct(productId: number) {
    this.router.navigate(['view', productId]);
  }

  scrollLeft() {
    this.scrollContainer?.nativeElement.scrollBy({
      left: -400,
      behavior: 'smooth',
    });
  }

  scrollRight() {
    this.scrollContainer?.nativeElement.scrollBy({
      left: 400,
      behavior: 'smooth',
    });
  }
}
