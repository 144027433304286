import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.jwtKey);
    const router = inject(Router);

    if (token) {
      const clonedReq = req.clone({
        setHeaders: {
          'Custom-Authorization': token,
        },
      });
      return next.handle(clonedReq).pipe(
        tap(
          () => {},
          (error) => {
            if (error.status === 401) {
              localStorage.removeItem(environment.jwtKey);
              router.navigate(['/login']);
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
