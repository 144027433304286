<div class="footer">
  <div class="columns">
    <ng-container
      *ngIf="service.footerInfo$ | async as footerInfo; else footerLoading"
    >
      <div class="malahi-description column">
        <div class="flex">
          <app-ngx-ik-image-cdn
            path="images/malahi-logo.svg"
            width="80"
          ></app-ngx-ik-image-cdn>
        </div>
        <div class="flex flex-wrap payment-methods mt-3 gap-3">
          <div class="payment-methods-container">
            <img
              src="assets/images/tabby-new.webp"
              height="15"
              alt="tabby-payment-method"
            />
          </div>
          <div class="payment-methods-container">
            <img
              height="15"
              src="assets/images/visa.png"
              alt="visa-payment-method"
            />
          </div>
          <div class="payment-methods-container">
            <img
              height="15"
              src="assets/images/master-card.png"
              alt="master-card-payment-method"
            />
          </div>
          <div class="payment-methods-container">
            <img
              height="15"
              src="assets/images/Apple_Pay.png"
              alt="applepay-card-payment-method"
            />
          </div>
          <div class="payment-methods-container">
            <img
              height="15"
              src="assets/images/mada.png"
              alt="mada-card-payment-method"
            />
          </div>
          <div class="payment-methods-container">
            <img
              height="15"
              src="assets/images/tamara.png"
              alt="tamara-card-payment-method"
            />
          </div>
        </div>
        <p class="my-3">
          {{ "VAT_REGISTRATION_NUMBER" | translate }}: 300127479700003
        </p>
        <div [innerHTML]="footerInfo[0].content" class="column-content"></div>
        <!-- <div>{{ "Copy Right @2024, Malahi Company" }}</div> -->
      </div>
      <div class="about-us column">
        <h1>{{ footerInfo[1].title }}</h1>
        <div [innerHTML]="footerInfo[1].content" class="column-content"></div>
      </div>
      <div class="contact-us column">
        <h1>{{ footerInfo[2].title }}</h1>
        <div [innerHTML]="footerInfo[2].content" class="column-content"></div>
        <ng-container *ngFor="let item of footerInfo">
          <ng-container *ngIf="isLinkItem(item)">
            <div class="contact-info">
              <div class="contact-icon">
                <i class="{{ getItemIcon(item) }}"></i>
              </div>
              <div class="contact-data">
                <a href="{{ getItemLink(item) }}">{{ getItemStr(item) }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="useful-links column">
        <h1>{{ "footer.useful_links.title" | apiTranslation }}</h1>
        <div class="links">
          <a routerLink="/content/termsAndConditions">{{
            "footer.useful_links.links.terms" | apiTranslation
          }}</a>
          <!-- <a routerLink="/content/privacyPolicy">{{
            "footer.useful_links.links.privacy_policy" | apiTranslation
          }}</a> -->
          <!-- <a href="">{{ "footer.useful_links.links.branches" | apiTranslation }}</a> -->
          <a routerLink="/content/faq">{{
            "footer.useful_links.links.faq" | apiTranslation
          }}</a>
          <a routerLink="/content/aboutUs">{{
            "footer.useful_links.links.about_us" | apiTranslation
          }}</a>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="powered-by">
    <h1>Powered by</h1>
    <app-ngx-ik-image-cdn
      class="logo"
      path="images/malahi-logo.svg"
      width="45"
    ></app-ngx-ik-image-cdn>
  </div>
  <div class="arrow-twirled bidi-image">
    <app-ngx-ik-image-cdn
      path="images/arrow-twirled.svg"
    ></app-ngx-ik-image-cdn>
  </div>
</div>

<ng-template #footerLoading>
  <ng-container *ngFor="let _ of [].constructor(4)">
    <div class="column">
      <div class="column-title">
        <p-skeleton height="40px" width="100px"></p-skeleton>
      </div>
      <div class="column-content">
        <p-skeleton height="25vh"></p-skeleton>
      </div>
    </div>
  </ng-container>
</ng-template>
