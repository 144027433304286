import { Injectable, inject } from '@angular/core';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentViewerService {
  http = inject(CustomHttpClientService);

  getContent(slug: string) {
    return this.http.get(`/content/${slug}`).pipe(
      first(),
      map((res: any) => res['content'])
    );
  }
}
