import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { OrdersService } from '../../data-access/orders.service';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { environment } from '../../../../environments/environment';
import { checkoutApis } from '../../../booking/util/checkout.apis';
import { SkeletonModule } from 'primeng/skeleton';
import { Router } from '@angular/router';
import { DividerModule } from 'primeng/divider';

interface Order {
  id: number;
  date: Date;
  total: number;
  status: 'pending' | 'completed' | 'cancelled';
  paymentMethod: string;
  products: Array<{
    name: string;
    quantity: number;
    price: number;
  }>;
  attraction: {
    id: number;
    name: string;
    siteId: string;
    icon: string;
  };
  created_at: string;
  transactionAmount: number;
  user_data: string;
  transactionOTP: string;
  payment_mode: string;
  ticket_path: string;
  response: any;
}

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    ButtonModule,
    TagModule,
    DialogModule,
    NgxIkImageCdnComponent,
    SkeletonModule,
    DividerModule,
  ],
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  orders: Order[] = [];
  displayOrderDetails = false;
  selectedOrder: Order | null = null;
  ordersService = inject(OrdersService);
  isLoading = false;
  private router = inject(Router);

  ngOnInit() {
    this.getUserBookings();
  }

  getUserBookings() {
    this.isLoading = true;
    this.ordersService.getUserBookings().subscribe((res) => {
      this.orders = res;
      this.isLoading = false;
    });
  }

  getStatusSeverity(
    status: string
  ):
    | 'success'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'contrast'
    | undefined {
    const severityMap: {
      [key: string]:
        | 'success'
        | 'secondary'
        | 'info'
        | 'warning'
        | 'danger'
        | 'contrast';
    } = {
      pending: 'warning',
      completed: 'success',
      cancelled: 'danger',
    };
    return severityMap[status] || 'success';
  }

  showOrderDetails(order: Order) {
    this.selectedOrder = order;
    this.displayOrderDetails = true;
  }

  downloadFile(fileName: string, fileUrl: string) {
    let link = document.createElement('a');
    link.download = fileName;
    link.target = '_blank';
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadTicket(path: string) {
    const fileUrl = `${environment.baseUrl}${checkoutApis.downloadTicket(
      path.split('.pdf')[0]
    )}`;
    this.downloadFile('Ticket', fileUrl);
  }

  navigateToShop() {
    this.router.navigate(['/']);
  }
}
